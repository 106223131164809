import { useState } from "react";

export default function CommentsReadMore({ comments }) {
    const [readMore, setReadMore] = useState(false);


    if (window.location.pathname === "/professor") {
        return (
            <>
                Comment: {readMore ? comments : comments.slice(0, 150)} <a
                    onClick={() => setReadMore(!readMore)}
                    className="text-primary"
                    style={{
                        // marginLeft: "px",
                        cursor: "pointer",
                    }}
                >
                    {readMore ? "Read less" : "Read more"}
                </a>
            </>
        )
    } else {
        return (
            <p>
                <b>‣ </b>Comment:{" "}
                {readMore ? comments : comments.slice(0, 150)} {" "}
                <a
                    onClick={() => setReadMore(!readMore)}
                    className="text-primary"
                    style={{
                        // marginLeft: "px",
                        cursor: "pointer",
                    }}
                >
                    {readMore ? "Read less" : "Read more"}
                </a>
            </p>
        )
    }
}