import { Col, Row, Container, Button } from 'react-bootstrap';
import Class from "./img/Class.ico";
import Subject from "./img/Subject.png";
import Description from "./img/Description.png";

export const howToIcons = () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        ios = /iphone|ipod|ipad/.test(userAgent);
    
    if (window.innerWidth < 768) {
        return (
            <Container
                style={{
                    marginTop: "40px",
                }}
            >
                <h1
                    style={{
                        marginBottom: "50px",
                        marginTop: "-100px",
                        marginLeft: "30px",
                        color: "white",
                    }}
                >
                    Don't know where to start?
                </h1>
                <Row>
                    
                </Row>
                <Col
                    style={{
                        textAlign: "center",
                        
                    }}
                >
                    <img
                        src={Class}
                        alt="Class"
                        style={{
                            width: "100px",
                            height: "auto",
                            paddingBottom: "25px",
                        }}
                        />
                    <h3
                        style={{
                        }}
                    >
                        Search by class name
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=COSC+2436";
                        }}
                    >
                        Search "COSC 2436"
                    </Button>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h3
                        style={{
                            color: "white",
                            marginTop: "25px",
                            marginBottom: "45px",
                        }}
                    >
                        or
                    </h3>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <img
                            src={Subject}
                            alt="Subject"
                            style={{

                                width: "100px",
                                height: "auto",
                                marginTop: ".5px",
                                marginBottom: "2.5px",
                            }}
                        />
                    <h3
                        style={{
                            marginTop: "21.5px",
                        }}
                    >
                        Search by subject
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=ENGL";
                        }}
                    >
                        Search "ENGL"
                    </Button>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h3
                        style={{
                            color: "white",
                            marginTop: "25px",
                            marginBottom: "45px",
                        }}
                    >
                        or
                    </h3>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <img
                            src={Description}
                            alt="Description"
                            style={{

                                width: "100px",
                                height: "auto",
                                marginTop: "-5.5px",
                                marginBottom: "15px",
                            }}
                        />
                    <h3
                        style={{
                        }}
                    >
                        Search by description
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=writing";
                        }}
                    >
                        Search "writing"
                    </Button>
                </Col>
            </Container>
        );
    }

    else if (ios) {
        return (
            <Container
                style={{
                    marginTop: "40px",
                }}
            >
                <h1
                    style={{
                        marginBottom: "50px",
                        marginTop: "-100px",
                        color: "white",
                    }}
                >
                    Don't know where to start?
                </h1>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <img
                            src={Class}
                            alt="Class"
                            style={{

                                width: "100px",
                                height: "auto",
                                paddingBottom: "25px",
                            }}
                        />
                    <h3
                        style={{
                        }}
                    >
                        Search by class name
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=COSC+2436";
                        }}
                    >
                        Search "COSC 2436"
                    </Button>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h3
                        style={{
                            color: "white",
                            marginTop: "25px",
                            marginBottom: "45px",
                        }}
                    >
                        or
                    </h3>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <img
                            src={Subject}
                            alt="Subject"
                            style={{

                                width: "100px",
                                height: "auto",
                                marginTop: ".5px",
                                marginBottom: "2.5px",
                            }}
                        />
                    <h3
                        style={{
                            marginTop: "21.5px",
                        }}
                    >
                        Search by subject
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=ENGL";
                        }}
                    >
                        Search "ENGL"
                    </Button>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h3
                        style={{
                            color: "white",
                            marginTop: "25px",
                            marginBottom: "45px",
                        }}
                    >
                        or
                    </h3>
                </Col>
                <Col
                    style={{
                        textAlign: "center",
                    }}
                >
                    <img
                            src={Description}
                            alt="Description"
                            style={{

                                width: "100px",
                                height: "auto",
                                marginTop: "-5.5px",
                                marginBottom: "15px",
                            }}
                        />
                    <h3
                        style={{
                        }}
                    >
                        Search by description
                    </h3>
                    <Button
                        style={{
                            backgroundColor: "white",
                            color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                        onClick={() => {
                            window.location.href = "/search?q=writing";
                        }}
                    >
                        Search "writing"
                    </Button>
                </Col>
            </Container>
        );
    }
    else if (window.innerWidth < 992) {
        return (
            <Container>
                <h1
                    style={{
                        marginBottom: "50px",
                        marginTop: "-100px",
                        marginLeft: "0px",
                        color: "white",
                    }}
                >
                    Don't know where to start?
                </h1>
                <Row
                    style={{
                        marginLeft: "-10px",
                    }}
                >
                    <Col>
                        <img
                                src={Class}
                                alt="Class"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "40px",
                                    paddingBottom: "25px",
                                }}
                            />
                        <h3>
                            Search by class
                            <br />
                            name
                        </h3>
                        <Button
                            style={{
                                marginLeft: "0px",
                                backgroundColor: "white",
                                color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=COSC+2436";
                            }}
                        >
                            Search "COSC 2436"
                        </Button>
                    </Col>
                    <Col
                        style={{
                            marginLeft: "-120px",
                            marginRight: "-285px",
                            marginTop: "40px",
                        }}
                    >
                        <h3
                            style={{
                                color: "white",
                            }}
                        >
                            or
                        </h3>
                    </Col>
                    <Col
                        style={{
                            color: "white",
                        }}
                    >
                        <img
                                src={Subject}
                                alt="Subject"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "70px",
                                    marginTop: ".5px",
                                    marginBottom: "2.5px",
                                }}
                            />
                        <h3
                            style={{
                                marginTop: "30.5px",
                            }}
                        >
                            Search by subject
                        </h3>
                        <Button
                            style={{
                                marginLeft: "45px",
                                marginTop: "27.5px",
                                backgroundColor: "white",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                                color: "#0080ff",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=ENGL";
                            }}
                        >
                            Search "ENGL"
                        </Button>
                    </Col>
                    <Col
                        style={{
                            marginLeft: "-90px",
                            marginRight: "-285px",
                            marginTop: "37.5px",
                        }}
                    >
                        <h3
                            style={{
                                color: "white",
                            }}
                        >
                            or
                        </h3>
                    </Col>
                    <Col
                        style={{
                            color: "white",
                            marginRight: "-80px",
                        }}
                    >
                        <img
                                src={Description}
                                alt="Description"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "80px",
                                    marginBottom: "15px",
                                }}
                            />
                        <h3
                            style={{
                                marginTop: "0px",
                            }}
                        >
                            Search by description
                        </h3>
                        <Button
                            style={{
                                marginLeft: "45px",
                                marginTop: "25.5px",
                                backgroundColor: "white",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                                color: "#0080ff",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=writing";
                            }}
                        >
                            Search "writing"
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    else {
        return (
            <Container>
                <h1
                    style={{
                        marginBottom: "50px",
                        marginTop: "-100px",
                        marginLeft: "30px",
                        color: "white",
                    }}
                >
                    Don't know where to start?
                </h1>
                <Row
                    style={{
                        marginLeft: "20px",
                    }}
                >
                    <Col
                        style={{
                            color: "white",
                        }}
                    >
                        <img
                                src={Class}
                                alt="Class"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "80px",
                                    paddingBottom: "25px",
                                }}
                            />
                        <h3>Search by class name</h3>
                        <Button
                            style={{
                                marginLeft: "45px",
                                backgroundColor: "white",
                                color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=COSC+2436";
                            }}
                        >
                            Search "COSC 2436"
                        </Button>
                    </Col>
                    <Col
                        style={{
                            marginLeft: "-60px",
                            marginRight: "-285px",
                            marginTop: "40px",
                        }}
                    >
                        <h3
                            style={{
                                color: "white",
                            }}
                        >
                            or
                        </h3>
                    </Col>
                    <Col
                        style={{
                            color: "white",
                        }}
                    >
                        <img
                                src={Subject}
                                alt="Subject"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "70px",
                                    marginTop: "0px",
                                    marginBottom: "2.5px",
                                }}
                            />
                        <h3
                            style={{
                                marginTop: "30.5px",
                            }}
                        >
                            Search by subject
                        </h3>

                        <Button
                            style={{
                                marginLeft: "45px",
                                backgroundColor: "white",
                                color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=ENGL";
                            }}
                        >
                            Search "ENGL"
                        </Button>
                    </Col>
                    <Col
                        style={{
                            marginLeft: "-90px",
                            marginRight: "-285px",
                            marginTop: "40px",
                        }}
                    >
                        <h3
                            style={{
                                color: "white",
                            }}
                        >
                            or
                        </h3>
                    </Col>
                    <Col
                        style={{
                            color: "white",
                        }}
                    >
                        <img
                                src={Description}
                                alt="Description"
                                style={{

                                    width: "100px",
                                    height: "auto",
                                    marginLeft: "80px",
                                    marginTop: "-15px",
                                    marginBottom: "15px",
                                }}
                            />
                        <h3
                            style={{
                                marginTop: "17.5px",
                            }}
                        >
                            Search by description
                        </h3>
                        <Button
                            style={{
                                marginLeft: "70px",
                                backgroundColor: "white",
                                color: "#0080ff",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            }}
                            onClick={() => {
                                window.location.href = "/search?q=writing";
                            }}
                        >
                            Search "writing"
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}