import { Col } from 'react-bootstrap';
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const socials = () => {
    if (window.innerWidth <= 414) {
        return (
            <>
                <Col>
                    <p
                        style={{
                            fontFamily: "Inter vars, sans-serif"
                        }}
                    >
                        © 2023 CourseFind
                    </p>
                </Col>
                <Col
                    style={{
                        textAlign: "right",
                    }}
                >
                    {/* <a
                        href="https://www.instagram.com/OpenEd.fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon
                            style={{
                                fontSize: "25px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                    <a
                        href="https://twitter.com/coursefindcom"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/company/OpenEd-fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkedInIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                </Col>
            </>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <>
                <Col>
                    <p
                        style={{
                            fontFamily: "Inter vars, sans-serif"
                        }}
                    >
                        © 2023 CourseFind
                    </p>
                </Col>
                <Col
                    style={{
                        textAlign: "right",
                    }}
                >
                    {/* <a
                        href="https://www.instagram.com/OpenEd.fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon
                            style={{
                                fontSize: "25px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                    <a
                        href="https://twitter.com/coursefindcom"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/company/OpenEd-fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkedInIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                </Col>
            </>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <>
                <Col>
                    <p
                        style={{
                            fontFamily: "Inter vars, sans-serif"
                        }}
                    >
                        © 2023 CourseFind
                    </p>
                </Col>
                <Col
                    style={{
                        textAlign: "right",
                    }}
                >
                    {/* <a
                        href="https://www.instagram.com/OpenEd.fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon
                            style={{
                                fontSize: "25px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                    <a
                        href="https://twitter.com/coursefindcom"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/company/OpenEd-fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkedInIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                </Col>
            </>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <>
                <Col>
                    <p
                        style={{
                            fontFamily: "Inter vars, sans-serif"
                        }}
                    >
                        © 2023 CourseFind
                    </p>
                </Col>
                <Col
                    style={{
                        textAlign: "right",
                    }}
                >
                    {/* <a
                        href="https://www.instagram.com/OpenEd.fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon
                            style={{
                                fontSize: "25px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                    <a
                        href="https://twitter.com/coursefindcom"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/company/OpenEd-fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkedInIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                </Col>
            </>
        );
    } else {
        return (
            <>
                <Col>
                    <p
                        style={{
                            fontFamily: "Inter vars, sans-serif"
                        }}
                    >
                        © 2023 CourseFind
                    </p>
                </Col>
                <Col
                    style={{
                        textAlign: "right",
                    }}
                >
                    {/* <a
                        href="https://www.instagram.com/OpenEd.fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon
                            style={{
                                fontSize: "25px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                    <a
                        href="https://twitter.com/coursefindcom"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a>
                    {/* <a
                        href="https://www.linkedin.com/company/OpenEd-fyi/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkedInIcon
                            style={{
                                fontSize: "25px",
                                marginLeft: "15px",
                                color: "#0080ff",
                                fontFamily: "Inter vars, sans-serif"
                            }}
                        />
                    </a> */}
                </Col>
            </>
        );
    }
}