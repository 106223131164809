import { Pricing } from "../filter/pricing";
import { Transferability } from "../filter/transferability";
import { ProfessorFilter } from "../filter/professor";
import { Navbar } from "react-bootstrap";

export const FilterNav = ({ responseData }) => {

    if (window.innerWidth <= 414) {

        return (
            <Navbar
                bg="transparent"
            >
                <Navbar.Collapse
                >
                    <Pricing />
                    <Transferability />
                    <ProfessorFilter />                    
                </Navbar.Collapse>
            </Navbar>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginBottom: "-7.5px"
                }}
            >
                <Navbar.Collapse>
                    <Pricing />
                    <Transferability />
                    <ProfessorFilter />
                </Navbar.Collapse>
            </Navbar>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "-15px"
                }}
            >
                <Navbar.Collapse>
                    <Pricing />
                    <Transferability />
                    <ProfessorFilter />
                </Navbar.Collapse>
            </Navbar>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "-15px"
                }}
            >
                <Navbar.Collapse>
                    <Pricing />
                    <Transferability />
                    <ProfessorFilter />
                </Navbar.Collapse>
            </Navbar>
        );
    } else {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "-15px"
                }}
            >
                <Navbar.Collapse>
                    <Pricing />
                    <Transferability />
                    <ProfessorFilter />
                </Navbar.Collapse>
            </Navbar>
        );
    }
}