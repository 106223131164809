export const Breaks = ({ searchResultLength }) => {
    if (searchResultLength === 0) {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        )
    } else if (searchResultLength === 1) {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        )
    } else if (searchResultLength === null) {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        )
    } else {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
            </>
        )
    }

}