const axios = require('axios');

export const professorDataAcquisitionCourse = async (professorIds) => {
    let professorDataa = [];

    for (const professor_id of professorIds) {
        try {
            const response = await axios.get(`https://sba3mbqeen6dae6iselkesn7cy0eauhh.lambda-url.us-east-2.on.aws/?professor_id=${professor_id['_id'].toString()}`);
            professorDataa.push(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    let professorData = [];
    professorDataa.forEach((professor, key) => {
        let averageAverageRating = 0;
        let averageClarity = 0;
        let averageHelpfulness = 0;
        let ratingsCount = 0;
        let ratings = [];
        professor.courses.forEach((course) => {
            course.ratings.forEach((rating) => {
                averageAverageRating += rating.difficulty_rating;
                averageClarity += rating.clarity_rating;
                averageHelpfulness += rating.helpfulness_rating;
                ratingsCount++;
                let date = rating.date.split(" ")[0];
                ratings.push({
                    comment: rating.comment,
                    attendance: rating.attendance === "" || rating.attendance === null ? "N/A" : rating.attendance,
                    date: date,
                    userGrade: rating.user_grade === "" || rating.user_grade === null ? "N/A" : rating.user_grade,
                    className: course.class,
                    format: rating.online ? "Online" : "In Person"
                });
            })
        })
        averageAverageRating = averageAverageRating / ratingsCount;
        averageClarity = averageClarity / ratingsCount;
        averageHelpfulness = averageHelpfulness / ratingsCount;
        averageAverageRating = Math.round(averageAverageRating * 100) / 100;
        averageClarity = Math.round(averageClarity * 100) / 100;
        averageHelpfulness = Math.round(averageHelpfulness * 100) / 100;
        let averageRating = (averageAverageRating + averageClarity + averageHelpfulness) / 3;
        averageRating = Math.round(averageRating * 100) / 100;
        professorData.push({
            name: professor.name,
            averageRating: averageRating,
            averageAverageRating: averageAverageRating,
            averageClarity: averageClarity,
            averageHelpfulness: averageHelpfulness,
            ratingsCount: ratingsCount,
            ratings: ratings,
            link: professor.link,
            id: professor.id
        })
    })
    return professorData;
}