import Pagination from 'react-bootstrap/Pagination';
import { useLocation } from 'react-router-dom';

export const SearchPagination = ({ numOfPages, activePage }) => {
    let items = [];
    let firstLabel = "«";
    let previousLabel = "‹";
    let nextLabel = "›";
    let lastLabel = "»";
    let ellipsis = "...";

    
    const params = new URLSearchParams(useLocation().search);
    if (params.get('page') === null) {
        activePage = 1;
    } else {
        activePage = parseInt(params.get('page'));
    }

    const setPage = (numberToAdd) => {
        params.set('page', activePage + numberToAdd);
        return `/search?${params.toString()}`;
    }

    const lastPage = () => {
        params.set('page', numOfPages);
        return `/search?${params.toString()}`;
    }

    const firstPage = () => {
        params.set('page', 1);
        return `/search?${params.toString()}`;
    }

    const subtractPage = (numberToSubtract) => {
        params.set('page', activePage - 4);
        return `/search?${params.toString()}`;
    }
        

    const truncatePagination = () => {
        if (numOfPages <= 5) {
            for (let number = 1; number <= numOfPages; number++) {
                params.set('page', number);
                const link = `/search?${params.toString()}`
                items.push(
                    <Pagination.Item 
                        key={number} 
                        active={number === activePage} 
                        activeLabel=""
                        href={link}
                    >
                        {number}
                    </Pagination.Item>,
                );
            }
        } else if (numOfPages > 5 && activePage <= 5) {
            for (let number = 1; number <= 5; number++) {
                params.set('page', number);
                const link = `/search?${params.toString()}`
                items.push(
                    <Pagination.Item 
                        key={number} 
                        active={number === activePage} 
                        activeLabel="" 
                        href={link}
                    >
                        {number}
                    </Pagination.Item>,
                );
            }
            items.push(
                <Pagination.Item 
                    key={6} 
                    href={setPage(5)}
                >
                    {ellipsis}
                </Pagination.Item>,
                <Pagination.Item 
                    key={numOfPages} 
                    href={lastPage()}
                >
                    {numOfPages}
                </Pagination.Item>,
            );
        } else if (numOfPages > 5 && activePage > 5 && (numOfPages - activePage) > 5) {
            items.push(
                <Pagination.Item 
                    key={1} 
                    href={firstPage()}
                >
                    {1}
                </Pagination.Item>,
                <Pagination.Item 
                    key={2} 
                    href={subtractPage(4)}
                >
                    {ellipsis}
                </Pagination.Item>,
            )

            for (let number = activePage; number <= activePage + 4; number++) {
                params.set('page', number);
                const link = `/search?${params.toString()}`
                items.push(
                    <Pagination.Item 
                        key={number} 
                        active={number === activePage} 
                        activeLabel="" 
                        href={link}
                    >
                        {number}
                    </Pagination.Item>,
                );
            }
            items.push(
                <Pagination.Item 
                    key={4} 
                    href={setPage(4)}
                >
                    {ellipsis}
                </Pagination.Item>,
                <Pagination.Item 
                    key={numOfPages} 
                    href={lastPage()}
                >
                    {numOfPages}
                </Pagination.Item>,
            );
        } else if (numOfPages > 5 && activePage > 5 && (numOfPages - activePage) <= 5) {
            items.push(
                <Pagination.Item
                    key={1}
                    href={firstPage()}
                >
                    {1}
                </Pagination.Item>,
                <Pagination.Item
                    key={2}
                    href={subtractPage(4)}
                >
                    {ellipsis}
                </Pagination.Item>,
            )
            for (let number = (numOfPages - 5); number <= numOfPages; number++) {
                params.set('page', number);
                const link = `/search?${params.toString()}`
                items.push(
                    <Pagination.Item
                        key={number}
                        active={number === activePage}
                        activeLabel=""
                        href={link}
                    >
                        {number}
                    </Pagination.Item>,
                );
            }
            for (let number = activePage; number <= 3; number++) {
                params.set('page', number);
                const link = `/search?${params.toString()}`

                items.push(
                    <Pagination.Item
                        key={number}
                        active={number === activePage}
                        activeLabel=""
                        href={link}
                    >
                        {number}
                    </Pagination.Item>,
                );
            }
        }

        return items;
    }
    

    const paginationFirst = () => {
        if (activePage === 1) {
            return (
                <Pagination.Item disabled>
                    {firstLabel}
                </Pagination.Item>
            );
        } else {
            return (
                <Pagination.Item href={firstPage()}>
                    {firstLabel}
                </Pagination.Item>
            );
        }
    }

    const paginationPrevious = () => {
        if (activePage === 1) {
            return (
                <Pagination.Item disabled>
                    {previousLabel}
                </Pagination.Item>
            );
        } else {
            params.set('page', activePage - 1);
            const link = `/search?${params.toString()}`
            return (
                <Pagination.Item href={link}>
                    {previousLabel}
                </Pagination.Item>
            );
        }
    }

    const paginationNext = () => {
        if (activePage === numOfPages) {
            return (
                <Pagination.Item disabled>
                    {nextLabel}
                </Pagination.Item>
            );
        } else {
            params.set('page', activePage + 1);
            const link = `/search?${params.toString()}`
            return (
                <Pagination.Item href={link}>
                    {nextLabel}
                </Pagination.Item>
            );
        }
    }

    const paginationLast = () => {
        if (activePage === numOfPages) {
            return (
                <Pagination.Item disabled>
                    {lastLabel}
                </Pagination.Item>
            );
        } else {
            return (
                <Pagination.Item href={lastPage()}>
                    {lastLabel}
                </Pagination.Item>
            );
        }
    }

    // if (!sessionStorage.getItem('token')) {
    //     return <></>;
    // } 
    if (window.innerWidth <= 414) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination size="sm">
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination size="sm">
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    } else if (window.innerWidth <= 1150 && window.innerWidth > 769) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination>
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 1150) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination>
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination>
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    } else {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination>
                    {paginationFirst()}
                    {paginationPrevious()}
                    {truncatePagination()}
                    {paginationNext()}
                    {paginationLast()}
                </Pagination>
            </div>
        );
    }
}