import { useState, useRef } from "react";
import { RegisterButton, LoginButton, LogoutButton } from "./buttons";
import {
    ControlledMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import MenuIcon from "@mui/icons-material/Menu";

export const ButtonMenu = () => {
    const ref = useRef(null);
    const [controlledMenuState, setControlledMenuState] = useState("closed");

    const handleControlledMenuClick = () => {
        if (controlledMenuState === "closed") {
            setControlledMenuState("open");
        } else {
            setControlledMenuState("closed");
        }
    };

    const authButtons = () => {
        // check if "token" is in sessionStorage
        if (sessionStorage.getItem("token")) {
            return (
                <>
                    <LogoutButton />
                </>
            );
        } else {
            return (
                <>
                    <RegisterButton />
                    <LoginButton />
                </>
            )
        }
    }


    if (window.innerWidth <= 414) {
        return (
            <>
                <MenuIcon
                    ref={ref}
                    onClick={handleControlledMenuClick}
                    style={{
                        background: "transparent",
                        border: "none",
                        marginRight: "20px",
                    }}
                />
                <ControlledMenu
                    anchorRef={ref}
                    state={controlledMenuState}
                    style={{
                        marginLeft: "10px",
                    }}
                >
                    {authButtons()}
                </ControlledMenu>
            </>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <>
                <MenuIcon
                    ref={ref}
                    onClick={handleControlledMenuClick}
                    style={{
                        background: "transparent",
                        border: "none",
                        marginRight: "20px",
                    }}
                />
                <ControlledMenu
                    anchorRef={ref}
                    state={controlledMenuState}
                    style={{
                        marginLeft: "10px",
                    }}
                >
                    {authButtons()}
                </ControlledMenu>
            </>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <>
                {authButtons()}
            </>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <>
                {authButtons()}
            </>
        );
    } else {
        return (
            <>
                {authButtons()}
            </>
        );
    }
}