import { useLocation } from "react-router"
import { useHistory } from "react-router";
import { Button, ButtonGroup, Navbar } from "react-bootstrap";

export const CurrentFilterNav = () => {
    const params = new URLSearchParams(useLocation().search);
    const history = useHistory();

    const onClick = (event) => {
        let buttonValue = event.target.value;
        let tempTransferUniversities = params.get("transferability");
        if (buttonValue === "transferability") {
            tempTransferUniversities = tempTransferUniversities.replace(`${event.target.name},`, "");
            tempTransferUniversities = tempTransferUniversities.replace(`,${event.target.name}`, "");
            tempTransferUniversities = tempTransferUniversities.replace(`${event.target.name}`, "");
            if (tempTransferUniversities.length === 0) {
                params.delete("transferability");
                params.delete("page");
            } else {
                params.set("transferability", tempTransferUniversities);
            }
        } else {
            params.delete(buttonValue);
            params.delete("page");
        }
        history.push({
            pathname: "/search",
            search: params.toString()
        });
        window.location.reload();
    }
    
    const checkParams = () => {
        let paramsArray = [];
        for (let [key, value] of params) {
            if (key === "transferability") {
                if (value.includes(",")) {
                    console.log("value: ", value)
                    let splitValue = value.split(",");
                    for (let i = 0; i < splitValue.length; i++) {
                        paramsArray.push([key, splitValue[i]]);
                    }
                } else {
                    paramsArray.push([key, value]);
                }
            } else if (key !== "q" && key !== "page") {
                paramsArray.push([key, value]);
            }
        }

        const paramsMap = {
            "avgRatingsSort": {
                "bestToWorst": "Average Professor Rating: Best to Worst",
                "worstToBest": "Average Professor Rating: Worst to Best"
            },
            "maxPrice": "Max Price: $",
            "minPrice": "Minimum Price: $",
            "residency": "Residency: ",
            "transferability": "Transferability: ",
            "minAverageRatings": "Minimum Average Rating: ",
            "maxAverageRatings": "Maximum Average Rating: ",
            "minClarityRatings": "Minimum Clarity Rating: ",
            "maxClarityRatings": "Maximum Clarity Rating: ",
            "minDifficultyRatings": "Minimum Difficulty Rating: ",
            "maxDifficultyRatings": "Maximum Difficulty Rating: ",
            "minHelpfulnessRatings": "Minimum Helpfulness Rating: ",
            "maxHelpfulnessRatings": "Maximum Helpfulness Rating: ",
            "sort": {
                "asc": "Price Sort: Low to High",
                "desc": "Price Sort: High to Low"
            }
        }

        let items = [];
        for (let i = 0; i < paramsArray.length; i++) {
            if (typeof paramsMap[paramsArray[i][0]] === "object") {
                paramsArray[i][2] = paramsMap[paramsArray[i][0]][paramsArray[i][1]];
            }  else {
                paramsArray[i][2] = paramsMap[paramsArray[i][0]] + paramsArray[i][1];
            }
            if (i === paramsArray.length - 1) {
                let x = "\u00d7";
                items.push(
                    <ButtonGroup
                        style={{
                            marginBottom: "2px",
                        }}
                        onClick={onClick}
                    >
                        <Button
                            variant="primary"
                            style={{
                                marginLeft: "10px",
                                borderTopLeftRadius: "25px",
                                borderBottomLeftRadius: "25px",
                            }}
                            value={paramsArray[i][0]}
                            name={paramsArray[i][1]}
                        >
                            {paramsArray[i][2]}
                        </Button>
                        <Button
                            variant="primary"
                            style={{
                                borderTopRightRadius: "25px",
                                borderBottomRightRadius: "25px",
                                marginRight: "12px"
                            }}
                            value={paramsArray[i][0]}
                            name={paramsArray[i][1]}
                        >
                            {x}
                        </Button>
                    </ButtonGroup>
                );
            } else {
                let x = "\u00d7";
                items.push(
                    <ButtonGroup
                        style={{
                            marginBottom: "2px",
                        }}
                        onClick={onClick}
                    >
                        <Button
                            variant="primary"
                            style={{
                                marginLeft: "10px",
                                borderTopLeftRadius: "25px",
                                borderBottomLeftRadius: "25px"
                            }}
                            value={paramsArray[i][0]}
                            name={paramsArray[i][1]}
                        >
                            {paramsArray[i][2]}
                        </Button>
                        <Button
                            variant="primary"
                            style={{
                                borderTopRightRadius: "25px",
                                borderBottomRightRadius: "25px",
                            }}
                            value={paramsArray[i][0]}
                            name={paramsArray[i][1]}
                        >
                            {x}
                        </Button>
                    </ButtonGroup>
                );

            }
        }

        return items.map((item, key) => {
            return (
                <div key={key}>
                    {item}
                </div>
            );
        });
    }

    return (
        <div
            style={{
                overflowX: "scroll",
                overflowY: "hidden",
                scrollbarWidth: "none",
                msOverflowStyle: "none"
            }}
        >
            <style>
                {`
                    ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
            <Navbar
                style={{
                    whiteSpace: "nowrap",
                    width: "100%",
                }}
            >
                {checkParams()}
            </Navbar>
        </div>
    );

}