import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import { Helmet } from 'react-helmet';
import { getSessionId } from "../utils/session";
import { useEffect } from "react";
import { recordView } from "../services/AnalyticsService";

function TermsAndConditions() {
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);
    
    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>CourseFind | Terms and Conditions</title>
                <meta name="description" content="Learn about CourseFind's Terms and Conditions that govern your use of our platform and services." />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                <h1>Terms and Conditions</h1>
                <br />
                <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the CourseFind website (the "Service") operated by CourseFind ("us", "we", or "our").</p>
                <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service.</p>
                <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you do not have permission to access the Service.</p>
                <br />
                <h2>Intellectual Property</h2>
                <p>The Service and its original content, features, and functionality are and will remain the exclusive property of CourseFind and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of CourseFind.</p>
                <br />
                <h2>Links to Other Websites</h2>
                <p>Our Service may contain links to third-party websites or services that are not owned or controlled by CourseFind.</p>
                <p>CourseFind has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
                <p>You acknowledge and agree that CourseFind shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.</p>
                <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>
                <br />
                <h2>Termination</h2>
                <p>We may terminate or suspend your access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                <br />
                <h2>Disclaimer</h2>
                <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
                <p>CourseFind, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components, or d) the results of using the Service will meet your requirements.</p>
                <br />
                <h2>Governing Law</h2>
                <p>These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.</p>
                <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the Service.</p>
                <br />
                <h2>Changes</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, you are no longer authorized to use the Service.</p>
                <br />
                <h2>Contact Us</h2>
                <p>If you have any questions about these Terms and Conditions, please contact us:</p>
                <p>Email: <a href="mailto:support@course-find.com">support@course-find.com</a></p>
                <br />
                <br />
                <br />
            </Container>
            <SearchFooter />
        </div>
    );
}

export default withRouter(TermsAndConditions);