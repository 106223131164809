import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';

export const Search = () => {
    const params = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const [query, setQuery] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        params.set("q", query);
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
    };

    if (window.innerWidth <= 414) {
        return (
            <Form onSubmit={handleSubmit}>
                <InputGroup
                    className="mb-3"
                >
                    <Form.Control
                        placeholder="Search by course, subject, or keyword..."
                        aria-label="Search by course, subject, or keyword..."
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                        }}
                    />
                </InputGroup>
            </Form>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <Form onSubmit={handleSubmit}>
                <InputGroup
                    className="mb-3"
                    style={{
                        width: "75%"
                    }}
                >
                    <Form.Control
                        placeholder="Search by course, subject, or keyword..."
                        aria-label="Search by course, subject, or keyword..."
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                        }}
                    />
                    <Button
                        variant="primary"
                        id="button-addon2"
                        onClick={handleSubmit}
                        style={{
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px"
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Form>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <Form onSubmit={handleSubmit}>
                <InputGroup
                    className="mb-3"
                    style={{
                        width: "50%"
                    }}
                >
                    <Form.Control
                        placeholder="Search by course, subject, or keyword..."
                        aria-label="Search by course, subject, or keyword..."
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                        }}
                    />
                    <Button
                        variant="primary"
                        id="button-addon2"
                        onClick={handleSubmit}
                        style={{
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px"
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Form>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <Form onSubmit={handleSubmit}>
                <InputGroup
                    className="mb-3"
                    style={{
                        width: "47.5%"
                    }}
                >
                    <Form.Control
                        placeholder="Search by course, subject, or keyword..."
                        aria-label="Search by course, subject, or keyword..."
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                        }}
                    />
                    <Button
                        variant="primary"
                        id="button-addon2"
                        onClick={handleSubmit}
                        style={{
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px"
                        }}
                    >
                        <SearchIcon />
                    </Button>
                </InputGroup>
            </Form>
        );
    } else {
        return (
            <Form onSubmit={handleSubmit}>
                <InputGroup
                    className="mb-3"
                    style={{
                        width: "50%"
                    }}
                >
                    <Form.Control
                        placeholder="Search by course, subject, or keyword..."
                        aria-label="Search by course, subject, or keyword..."
                        aria-describedby="basic-addon2"
                        onChange={(e) => {
                            setQuery(e.target.value);
                        }}
                        style={{
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                        }}
                    />
                    <Button
                        variant="primary"
                        id="button-addon2"
                        onClick={handleSubmit}
                        style={{
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px"
                        }}
                    >
                        Search
                    </Button>
                </InputGroup>
            </Form>
        );
    }
}