import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CommentsReadMore from "./commentsReadMore";
import { IoEllipsisHorizontalCircleOutline } from "react-icons/io5";

export default function ProfessorRatingsReadMore({ professorRatings }) {
    const [readMore, setReadMore] = useState(false);

    const fullProfessorRatingsList = professorRatings.map((professorRating, key) => {

        return (
            <React.Fragment
                key={Math.random()}
            >
                <li>
                    Date: {professorRating.date}
                </li>
                <dl>
                    <dd
                        style={{
                            marginBottom: "-10px"
                        }}
                    ><CommentsReadMore comments={professorRating.comment} />
                    </dd>
                    {professorRating.format && <dd><b>‣ </b>Format: {professorRating.format}</dd>}
                    <dd><b>‣ </b>Attendance: {professorRating.attendance}</dd>
                    <dd><b>‣ </b>User's Grade: {professorRating.userGrade}</dd>
                </dl>
            </React.Fragment>
        )
    })

    const slicedProfessorRatingsList = professorRatings.slice(0, 2).map((professorRating, key) => {
        return (
            <React.Fragment
                key={Math.random()}
            >
                <li>
                    Date: {professorRating.date}
                </li>
                <dl>
                    <dd
                        style={{
                            marginBottom: "-10px"
                        }}
                    ><CommentsReadMore comments={professorRating.comment} />
                    </dd>
                    {professorRating.format && <dd><b>‣ </b>Format: {professorRating.format}</dd>}
                    <dd><b>‣ </b>Attendance: {professorRating.attendance}</dd>
                    <dd><b>‣ </b>User's Grade: {professorRating.userGrade}</dd>
                </dl>
            </React.Fragment>
        )
    })

    const returnProfessorRatingsList = () => {
        if (fullProfessorRatingsList.length > 2) {
            return readMore ? fullProfessorRatingsList : slicedProfessorRatingsList;
        } else {
            return fullProfessorRatingsList;
        }
    }

    const returnProfessorRatingsListButton = () => {
        if (fullProfessorRatingsList.length > 2) {
            return (
                <Button
                    variant="link"
                    onClick={() => setReadMore(!readMore)}
                    className="text-primary"
                    style={{
                        marginTop: "-20px",
                    }}
                >
                    {readMore ? "Collapse" : <IoEllipsisHorizontalCircleOutline
                        // make it bigger
                        style={{
                            fontSize: "27.5px",
                        }}
                    />}
                </Button>
            )
        }
    }

    return (
        <>
            <li>Ratings</li>
            <ul>
                {returnProfessorRatingsList()}
            </ul>
            {returnProfessorRatingsListButton()}
        </>
    )
}