import { Navbar } from "react-bootstrap";
import { ButtonMenu } from "../nav/buttonMenu";
import SearchInput from "./SearchInput";
import Logo from '../../assets/img/logo.png'

export default function SearchNavBar() {
    if (window.innerWidth <= 414) {
        return (
            <>
                <Navbar
                    bg="transparent"
                    style={{
                        marginTop: "12.5px",
                        marginBottom: "-10px",
                        background: "#0080ff",
                    }}
                >
                    <Navbar.Brand
                        href="/"
                        style={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "bold",
                            color: "#0080ff",
                            marginLeft: "12.5px",
                            marginRight: "5px"
                        }}
                    >
                        <img
                            src={Logo}
                            style={{
                                width: "130px",
                                height: "auto",
                                marginTop: "-10px"
                            }}
                            alt="CourseFind logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <ButtonMenu />
                    </Navbar.Collapse>
                </Navbar>
                <Navbar
                    bg="transparent"
                    style={{
                        background: "#0080ff",
                    }}
                >
                    <Navbar.Collapse className="justify-content-end">
                        <SearchInput />
                    </Navbar.Collapse>
                </Navbar>
            </>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <>
                <Navbar
                    bg="transparent"
                    style={{
                        marginTop: "12.5px",
                        marginBottom: "-10px",
                        background: "#0080ff",
                    }}
                >
                    <Navbar.Brand
                        href="/"
                        style={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: "bold",
                            color: "#0080ff",
                            marginLeft: "12.5px",
                            marginRight: "5px"
                        }}
                    >
                        <img
                            src={Logo}
                            style={{
                                width: "130px",
                                height: "auto",
                                marginTop: "-10px"
                            }}
                            alt="CourseFind logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <ButtonMenu />
                    </Navbar.Collapse>
                </Navbar>
                <Navbar
                    bg="transparent"
                    style={{
                        background: "#0080ff",
                    }}
                >
                    <Navbar.Collapse className="justify-content-end">
                        <SearchInput />
                    </Navbar.Collapse>
                </Navbar>
            </>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "12.5px",
                    marginBottom: "20px",
                    background: "#0080ff",
                }}
            >
                <Navbar.Brand
                    href="/"
                    style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: "bold",
                        color: "#0080ff",
                        marginLeft: "12.5px",
                        marginRight: "5px"
                    }}
                >
                        <img
                            src={Logo}
                            style={{
                                width: "130px",
                                height: "auto",
                                marginTop: "-10px"
                            }}
                            alt="CourseFind logo"
                        />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <SearchInput />
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <ButtonMenu />
                </Navbar.Collapse>
            </Navbar>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "12.5px",
                    marginBottom: "20px",
                    background: "#0080ff",
                }}
            >
                <Navbar.Brand
                    href="/"
                    style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: "bold",
                        color: "#0080ff",
                        marginLeft: "12.5px",
                        marginRight: "5px"
                    }}
                >
                        <img
                            src={Logo}
                            style={{
                                width: "130px",
                                height: "auto",
                                marginTop: "-10px"
                            }}
                            alt="CourseFind logo"
                        />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <SearchInput />
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <ButtonMenu />
                </Navbar.Collapse>
            </Navbar>
        );
    } else {
        return (
            <Navbar
                bg="transparent"
                style={{
                    marginTop: "12.5px",
                    marginBottom: "20px",
                    background: "#0080ff",
                }}
            >
                <Navbar.Brand
                    href="/"
                    style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: "bold",
                        color: "#0080ff",
                        marginLeft: "12.5px",
                        marginRight: "5px"
                    }}
                >
                        <img
                            src={Logo}
                            style={{
                                width: "130px",
                                height: "auto",
                                marginTop: "-10px"
                            }}
                            alt="CourseFind logo"
                        />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <SearchInput />
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <ButtonMenu />
                </Navbar.Collapse>
            </Navbar>
        );
    }
}