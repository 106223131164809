import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { AuthNavBar } from "../components/auth/AuthNavBar";
import { Container, Row, Col, Form, FormControl, Button } from "react-bootstrap";
import Loading from "../components/Loading";
import axios from "axios";
import ErrorMessage from "../components/ErrorMessage";
import { Helmet } from 'react-helmet';
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";

function Login() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { data } = await axios.post("https://e0kf53lr91.execute-api.us-east-2.amazonaws.com/prod/login", {
                    email: email,
                    password: password,
                });
            setLoading(false);
            if (data.success) {
                sessionStorage.setItem("token", data.token);
                if (history.location.search.includes("next")) {
                    history.push(history.location.search.split("next=")[1]);
                } else {
                    history.push("/");
                }
            } else {
                setError(data.message)
            }
        } catch (err) {
            setError(error.response.data.message);
            setLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>CourseFind | Login</title>
                <meta name="description" content="Log in to your CourseFind account and gain access to personalized features and course recommendations." />
            </Helmet>
            <AuthNavBar />
            <Container
                style={{
                    fontFamily: "Inter var, sans-serif",
                    marginTop: "15px",
                }}
            >
                <h2>Login</h2>
                {loading && <Loading />}
                <Row>
                    <Col md="4">
                        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                        <Form
                            onSubmit={onSubmit}
                        >
                            <Form.Group controlId="emailId">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email"
                                />
                                <FormControl.Feedback type="invalid"></FormControl.Feedback>
                            </Form.Group>

                            <Form.Group controlId="passwordId">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                        <br />
                        <Button
                            style={{
                                marginRight: "10px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                backgroundColor: "#0080ff",
                                borderColor: "#0080ff",
                                color: "white",
                                borderRadius: "20px",
                            }}
                            onClick={onSubmit}
                        >
                            Continue
                        </Button>
                        <p className="mt-2">
                            Don't have account?{" "}
                            <Link
                                to="/register"
                                style={{
                                    color: "#0080ff",
                                    textDecoration: "none",
                                }}
                            >
                                Register
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default withRouter(Login);