export const TagLine = () => {
    if (window.innerWidth <= 414) {
        return (
            <h1 className="tagline">
                <span
                  className="tagline-text"
                  style={{
                    color: "#0080ff",
                    fontFamily: "Inter var, sans-serif",
                    fontWeight: "bold"
                  }}
                >
                  Discover community college courses!
                </span>
              </h1>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <h1 className="tagline">
                <span
                  className="tagline-text"
                  style={{
                    color: "#0080ff",
                    fontFamily: "Inter var, sans-serif",
                    fontWeight: "bold"
                  }}
                >
                  Discover community college courses!
                </span>
              </h1>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <h1 className="tagline">
                <span
                  className="tagline-text"
                  style={{
                    color: "#0080ff",
                    fontFamily: "Inter var, sans-serif",
                    fontWeight: "bold"
                  }}
                >
                  Discover community college courses!
                </span>
              </h1>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <h1 className="tagline">
                <span
                  className="tagline-text"
                  style={{
                    color: "#0080ff",
                    fontFamily: "Inter var, sans-serif",
                    fontWeight: "bold"
                  }}
                >
                  Discover community college courses!
                </span>
              </h1>
        );
    } else {
      return (
        <h1 className="tagline">
          <span
            className="tagline-text"
            style={{
              color: "#0080ff",
              fontFamily: "Inter var, sans-serif",
              fontWeight: "bold"
            }}
          >
            Discover community college courses!
          </span>
        </h1>
      );
    }
}