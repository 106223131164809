import React, { useState, useRef, useEffect } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import RateMyProfessor from '../../assets/img/rateMyProfessor.png';

function useClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
}

export default function RateMyProfessorPopUp({ professor, disable }) {
    const [showPopOver, setShowPopOver] = useState(false);
    const target = useRef(null);
    const popoverRef = useRef(null);

    useClickOutside(popoverRef, () => {
        setShowPopOver(false);
    });

    const popOver = (
        <Popover 
            id="popover-basic" 
            ref={popoverRef}
            style={{
                width: "50%",
                
            }}
        >
            <Popover.Title as="h3">
                Learn More
                <img
                    src={RateMyProfessor}
                    alt="Rate My Professor"
                    style={{
                        width: "22.5px",
                        height: "auto",
                        marginLeft: "10px",
                    }}
                />
            </Popover.Title>
            <Popover.Content>
                View more reviews for {professor.name} on{" "}
                <a 
                    href={professor.link} 
                    target="_blank"
                    rel="noreferrer"
                >RateMyProfessor</a>.
            </Popover.Content>
        </Popover>
    );

    if (disable) {
        return (
            <>
                <span ref={target}>
                    <IoIosInformationCircleOutline
                        style={{
                            color: "#0080ff",
                            marginBottom: "5px",
                            fontSize: "1.275rem",
                            cursor: "pointer",
                        }}
                    />
                </span>
                <Overlay
                    target={target.current}
                    show={showPopOver}
                    // for the placement, if the window.innerWidth is less than 768px, then the placement is "bottom"
                    // otherwise, the placement is "right"
                    placement={window.innerWidth < 768 ? "bottom" : "right"}
                    container={popoverRef.current}
                    rootClose
                    onHide={() => setShowPopOver(false)}
                >
                    {popOver}
                </Overlay>
            </>
        );

    } else {
        return (
            <>
                <span ref={target} onClick={() => setShowPopOver(!showPopOver)}>
                    <IoIosInformationCircleOutline
                        style={{
                            color: "#0080ff",
                            marginBottom: "5px",
                            fontSize: "1.275rem",
                            cursor: "pointer",
                        }}
                    />
                </span>
                <Overlay
                    target={target.current}
                    show={showPopOver}
                    // for the placement, if the window.innerWidth is less than 768px, then the placement is "bottom"
                    // otherwise, the placement is "right"
                    placement={window.innerWidth < 768 ? "bottom" : "right"}
                    container={popoverRef.current}
                    rootClose
                    onHide={() => setShowPopOver(false)}
                >
                    {popOver}
                </Overlay>
            </>
        );
    }
}