import { useState } from "react";

export default function DescriptionReadMore({ description }) {
    const [readMore, setReadMore] = useState(false);

    return (
        <p>
            {readMore ? description : description.slice(0, 150)}
            <a
                onClick={() => setReadMore(!readMore)}
                className="text-primary"
                style={{
                    marginLeft: "10px",
                    cursor: "pointer",
                }}
            >
                {readMore ? "Read less" : "Read more"}
            </a>
        </p>
    )
}