import { Container } from 'react-bootstrap';
import { TagLine } from '../hero/tagline';
import { Search } from '../hero/search';

export default function Hero() {
    return (
        <Container
            style={{
                backgroundColor: "transparent",
                paddingBottom: "130px",
                paddingTop: "100px",
                marginBottom: "30px",
            }}
        >
            <TagLine />
            <Search />
        </Container>
    );
}