// utils/session.js

export const getSessionId = () => {
    let sessionId = sessionStorage.getItem('sessionId');

    if (!sessionId) {
        sessionId = generateUniqueSessionId(); // you can use any logic to generate a unique ID.
        sessionStorage.setItem('sessionId', sessionId);
    }

    return sessionId;
};

const generateUniqueSessionId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};