import { Navbar } from "react-bootstrap";
import { ButtonMenu } from "../nav/buttonMenu";
import Logo from '../../assets/img/logo.png'


export default function LandingNavBar() {
    return (
        <Navbar
            bg="transparent"
            style={{
                marginTop: "12.5px",
                marginBottom: "20px",
                background: "navy",
            }}
        >
            <Navbar.Brand 
                href="/"
                style={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "bold",
                    color: "#0080ff",
                    marginLeft: "12.5px",
                    marginRight: "5px"
                }}
            >
                <img
                    src={Logo}
                    style={{
                        width: "130px",
                        height: "auto",
                    }}
                    alt="CourseFind logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                <ButtonMenu />
            </Navbar.Collapse>
        </Navbar>
    );
}
