import { Button } from "react-bootstrap";
import { useHistory } from "react-router";


export const RegisterButton = () => {
    const history = useHistory();

    const onClick = () => {
        // get the path (including query params if any) and push it to the history
        let path = window.location.pathname + window.location.search;

        if (path !== "/" && path !== "/login" && path !== "/register") {
            history.push(`/register?next=${path}`);
        } else {
            history.push("/register");
        }
    }

    if (window.innerWidth <= 414) {
        return <Button onClick={onClick} variant="outline-primary" style={{
              marginRight: "10px",
              marginLeft: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}>Register</Button>;
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return <Button onClick={onClick} variant="outline-primary" style={{
              marginRight: "10px",
              marginLeft: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}>Register</Button>;
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return <Button onClick={onClick} variant="outline-primary" style={{
              marginRight: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}>Register</Button>;
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return <Button onClick={onClick} variant="outline-primary" style={{
              marginRight: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}>Register</Button>;
    } else {
        return <Button onClick={onClick} variant="outline-primary" style={{
            marginRight: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            fontWeight: "bold",
            fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
        }}>Register</Button>;
    }
}

export const LoginButton = () => {
    const history = useHistory();

    let path = window.location.pathname + window.location.search;

    const onClick = () => {
        if (path !== "/" && path !== "/login" && path !== "/register") {
            history.push(`/login?next=${path}`);
        } else {
            history.push("/login");
        }
    }

    if (window.innerWidth <= 414) {
        return <Button onClick={onClick}
        style={{
              marginRight: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}
        >Login</Button>;
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return <Button onClick={onClick}
        style={{
              marginRight: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}
        >Login</Button>;
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return <Button onClick={onClick}
        style={{
              marginRight: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}
        >Login</Button>;
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return <Button onClick={onClick}
        style={{
              marginRight: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}
        >Login</Button>;
    } else {
        return <Button onClick={onClick}
            style={{
                marginRight: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            borderTopRightRadius: "25px"
            }}
        >Login</Button>;
    }
}

export const LogoutButton = () => {
    const onClick = () => {
        sessionStorage.removeItem("token");
        window.location.reload();
    }

    if (window.innerWidth <= 414) {
        return <Button 
            onClick={onClick}
            style={{
                marginRight: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px"
            }}
        >Logout</Button>;
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return <Button 
            onClick={onClick}
            style={{
                marginRight: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px"
            }}
        >Logout</Button>;
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return <Button 
            onClick={onClick}
            style={{
                marginRight: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px"
            }}
        >Logout</Button>;
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return <Button 
            onClick={onClick}
            style={{
                marginRight: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px"
            }}
        >Logout</Button>;
    } else {
        return <Button 
            onClick={onClick}
            style={{
                marginRight: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopRightRadius: "25px"
            }}
        >Logout</Button>;
    }
}