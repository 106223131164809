import { useState } from "react";
import ProfessorRatingsReadMore from "./ratingsReadMore";
import { AiOutlineLeft, AiOutlineDown } from "react-icons/ai";
import Popover from 'react-bootstrap/Popover';
import RateMyProfessor from '../../assets/img/rateMyProfessor.png';
import RateMyProfessorPopUp from "./rateMyProfessorPopUp";
import { Link } from "react-router-dom";

export default function ProfessorInformationCollapse({ professor }) {
    const [professorCollapsed, setProfessorCollapsed] = useState(false);

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const returnProfessorInformationButton = () => {
        if (professorCollapsed) {
            return (
                <span
                    onClick={() => setProfessorCollapsed(!professorCollapsed)}
                    style={{
                        marginLeft: "10px",
                        color: "black",
                        textDecoration: "none",
                        outline: "none",
                        // change the mouse cursor to a pointer
                        cursor: "pointer",
                    }}
                >
                    <AiOutlineDown
                        style={{
                            marginBottom: "3.25px"
                        }}
                    />
                </span>
            )
        } else {
            return (
                <span
                    onClick={() => setProfessorCollapsed(!professorCollapsed)}
                    style={{
                        marginLeft: "10px",
                        color: "black",
                        textDecoration: "none",
                        outline: "none",
                        cursor: "pointer",
                    }}
                >
                    <AiOutlineLeft
                        style={{
                            marginBottom: "3.25px"
                        }}
                    />
                </span>
            )
        }
    }

    const professorName = () => {

        return (
            <>
                <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to={`/professor?id=${professor.id}`}
                    style={{
                        color: 'black',
                        textDecoration: isHovered ? 'underline' : 'none',
                    }}
                >
                    {professor.name}
                </Link>
                <span
                    style={{
                        color: '#0080ff',
                        marginLeft: '5px',
                    }}
                >
                    <RateMyProfessorPopUp professor={professor} />
                </span>
            </>
        );
    };

    if (professorCollapsed) {
        return (
            <>
                <li>
                    {professorName()}
                    {returnProfessorInformationButton()}
                </li>
                <ul>
                    <li>Average Rating: {professor.averageRating}</li>
                    <li>Average Difficulty: {professor.averageAverageRating}</li>
                    <li>Average Clarity: {professor.averageClarity}</li>
                    <li>Average Helpfulness: {professor.averageHelpfulness}</li>
                    <ProfessorRatingsReadMore professorRatings={professor.ratings} />
                </ul>                
            </>
        )
    } else {
        return (
            <li>
                {professorName()}
                {returnProfessorInformationButton()}
            </li>
        )
    }
}