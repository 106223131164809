import React, { useState } from 'react';
import ProfessorInformationCollapse from './professorInformationCollapse';
import { FiPlus, FiMinus } from "react-icons/fi";
import { Button } from "react-bootstrap";
import { IoEllipsisHorizontalCircleOutline } from "react-icons/io5";

export default function ProfessorReadMore({ professorData, hide }) {
    const [professorCollapsed, setProfessorCollapsed] = useState(false);
    const [readMore, setReadMore] = useState(false);

    const fullProfessorList = professorData.map((professor) => {
        return (
            <React.Fragment
                key={Math.random()}
            >
                <ProfessorInformationCollapse professor={professor} />
            </React.Fragment>
        )
    });

    const slicedProfessorList = professorData.slice(0, 5).map((professor) => {
        return (
            <React.Fragment
                key={Math.random()}
            >
                <ProfessorInformationCollapse professor={professor} />
            </React.Fragment>
        )
    });

    const returnProfessorLabelButton = () => {
        // if (hide) {
        //     return (
        //         <React.Fragment>
        //             <b
        //                 style={{
        //                     marginBottom: "10px"
        //                 }}
        //             >*******</b>
        //             <span
        //                 // onClick={() => setProfessorCollapsed(!professorCollapsed)}
        //                 style={{
        //                     marginLeft: "10px",
        //                     color: "black",
        //                     textDecoration: "none",
        //                     outline: "none",
        //                     // cursor: "pointer",
        //                 }}
        //             >
        //                 <FiPlus
        //                     style={{
        //                         marginBottom: "5px"
        //                     }}
        //                 />
        //             </span>
        //         </React.Fragment>
        //     )
        // } else 
        if (professorCollapsed) {
            return (
                <React.Fragment>
                    <b
                        style={{
                            marginBottom: "10px"
                        }}
                    >Professors</b>
                    <span
                        onClick={() => setProfessorCollapsed(!professorCollapsed)}
                        style={{
                            marginLeft: "10px",
                            color: "black",
                            textDecoration: "none",
                            outline: "none",
                            cursor: "pointer",
                        }}
                    >
                        <FiMinus
                            style={{
                                marginBottom: "5px"
                            }}
                        />
                    </span>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <b
                        style={{
                            marginBottom: "10px"
                        }}
                    >Professors</b>
                    <span
                        onClick={() => setProfessorCollapsed(!professorCollapsed)}
                        style={{
                            marginLeft: "10px",
                            color: "black",
                            textDecoration: "none",
                            outline: "none",
                            cursor: "pointer",
                        }}
                    >
                        <FiPlus
                            style={{
                                marginBottom: "5px"
                            }}
                        />
                    </span>
                </React.Fragment>
            )
        }
    }

    const returnProfessorList = () => {
        if (fullProfessorList.length > 5) {
            return readMore ? fullProfessorList : slicedProfessorList;
        } else {
            return fullProfessorList;
        }
    }

    const returnProfessorReadMoreButton = () => {
        if (fullProfessorList.length > 5) {
            return (
                <Button
                    variant="link"
                    onClick={() => setReadMore(!readMore)}
                    className="text-primary"
                    style={{
                        marginTop: "-20px",
                    }}
                >
                    {readMore ? "See less" : <IoEllipsisHorizontalCircleOutline
                        // make it bigger
                        style={{
                            fontSize: "27.5px",
                        }}
                    />}
                </Button>
            )
        }
    }

    if (professorData.length === 0 && !hide) {
        return <b>No professors found</b>;
    } 
    // else if (professorData.length === 0 && hide) {
    //     return <b>*******</b>;
    // }

    if (professorCollapsed) {
        return (
            <React.Fragment>
                {returnProfessorLabelButton()}
                <ul>
                    {returnProfessorList()}
                </ul>
                {returnProfessorReadMoreButton()}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {returnProfessorLabelButton()}
            </React.Fragment>
        )
    }
}
