import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, FormControl } from "react-bootstrap";
import { AuthNavBar } from "../components/auth/AuthNavBar";
import axios from "axios";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import { Helmet } from 'react-helmet';
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";

function Register() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const history = useHistory();
    
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if (password !== confirmPassword) {
                setError("Passwords do not match.");
                setLoading(false);
                return;
            }
            const { data } = await axios.post("https://e0kf53lr91.execute-api.us-east-2.amazonaws.com/prod/register", {
                    name: name,
                    email: email,
                    password: password
                });
            setLoading(false);
            if (data.success) {
                sessionStorage.setItem("token", data.token);
                if (history.location.search.includes("next")) {
                    history.push(history.location.search.split("next=")[1]);
                } else {
                    history.push("/");
                }
            } else {
                setError("Registration failed. Please try again.")
            }
        } catch (err) {
            setError(error.response.data.message);
            setLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>CourseFind | Register</title>
                <meta name="description" content="Create a CourseFind account and unlock the full potential of our platform to help you find the perfect community college courses." />
            </Helmet>
            <AuthNavBar />
            <Container
                style={{
                    fontFamily: "Inter var, sans-serif",
                    marginTop: "15px",
                }}
            >
                <h2>Register</h2>
                {loading && <Loading />}
                <Row>
                    <Col md="4">
                        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
                        <Form
                            onSubmit={onSubmit}
                        >
                            <Form.Group controlId="nameId">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter name"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <FormControl.Feedback type="invalid"></FormControl.Feedback>
                            </Form.Group>
                            <Form.Group controlId="emailId">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <FormControl.Feedback type="invalid"></FormControl.Feedback>
                            </Form.Group>

                            <Form.Group controlId="passwordId">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Enter password"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="confirmPasswordId">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm password"
                                    style={{
                                        borderRadius: "20px"
                                    }}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                        <br />
                        <Button
                            color="primary"
                            style={{
                                backgroundColor: "#0080ff",
                                borderColor: "#0080ff",
                                color: "white",
                                borderRadius: "20px"
                            }}
                            type="submit"
                            onClick={onSubmit}
                        >
                            Continue
                        </Button>
                        <p className="mt-2">
                            Already have account?{" "}
                            <Link
                                to="/login"
                                style={{ 
                                    color: "#0080ff", 
                                    textDecoration: "none" 
                                }}
                            >
                                Login
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withRouter(Register);