import { useEffect } from 'react';
import Footer from '../components/Footer';
import Hero from '../components/landing/Hero';
import LandingNavBar from '../components/landing/LandingNavBar';
import { withRouter } from "react-router";
import HowTo from '../components/landing/HowTo';
import { Helmet } from 'react-helmet';
import { getSessionId } from '../utils/session';
import { recordView } from '../services/AnalyticsService';

function Landing() {
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page
        // check if there is a query parameter called utm_id
        const urlParams = new URLSearchParams(window.location.search);
        const utmId = urlParams.get('utm_id');
        if (utmId) {
            recordView({ sessionId, page, referrer: document.referrer || 'direct', utmId  });    
        }  else {
            recordView({ sessionId, page, referrer: document.referrer || 'direct' });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Discover Community College Courses | CourseFind</title>
                <meta name="description" content="Easily discover, compare, and apply to community college courses with CourseFind. Start your educational journey today!" />
            </Helmet>
            <LandingNavBar />
            <Hero />
            <HowTo />
            <Footer />
        </>
    )
}

export default withRouter(Landing);