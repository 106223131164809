import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import ProfessorReadMore from "../components/search/professorReadMore";
import { professorDataAcquisitionCourse } from "../components/search/professorDataAcquisitionCourse";
import { Button } from "react-bootstrap";
import DescriptionReadMore from "../components/search/descriptionReadMore";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";
import { LoginButton, RegisterButton } from "../components/nav/buttons";
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";

function CoursePage() {
    const [course, setCourse] = useState({});
    const [description, setDescription] = useState("");
    const [inDistrictPrice, setInDistrictPrice] = useState(0);
    const [inStatePrice, setInStatePrice] = useState(0);
    const [outOfStatePrice, setOutOfStatePrice] = useState(0);
    const [institution, setInstitution] = useState("");
    const [transfer, setTransfer] = useState([]);
    const [professors, setProfessors] = useState([]);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(true);
    const params = new URLSearchParams(useLocation().search);
    const identification = params.get("id");
    const [professorData, setProfessorData] = useState([]);

    useEffect(() => {
        let isMounted = true;
        retrieveCourseInformation(isMounted);
        return () => {
            isMounted = false;
        };
    }, []);

    // Add a new useEffect hook to fetch professor data
    useEffect(() => {
        const fetchProfessorData = async () => {
            const data = await professorDataAcquisitionCourse(professors);
            setProfessorData(data);
        };

        if (professors && professors.length > 0) {
            fetchProfessorData();
        }
    }, [professors]);

    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);


    
    const onClick = () => {
        let link = `${course.application}?utm_source=course-find.com`
        window.open(link, "_blank")
    }

    const retrieveCourseInformation = async (isMounted) => {
        try {
            const res = await axios.get(`https://gnsd4xlskyonr4ibrcmoc7t5gq0sghhj.lambda-url.us-east-2.on.aws/?course_id=${identification}`);
            if (isMounted) {
                setCourse(res.data);
                setName(res.data.name);
                setDescription(res.data.description);
                setInDistrictPrice(res.data.price.inDistrict);
                setInStatePrice(res.data.price.inState);
                setOutOfStatePrice(res.data.price.outOfState);
                setInstitution(res.data.institution);
                setTransfer(res.data.transfer);
                setProfessors(res.data.professor_ids);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };


    const transferArr = () => {
        if (transfer.length > 0) {
            return transfer.map((transferInstitution, key) => {
                return <li key={key}>{transferInstitution}</li>;
                // if (!sessionStorage.getItem("token")) return <li key={key}>********</li>;
                // else return <li key={key}>{transferInstitution}</li>;
            })
        } else {
            return <li>No transfer institutions found</li>;
        }
    }

    const checkIfLoading = () => {
        if (loading) {
            return (
                <>
                    <Loading />
                    {breaksIfLoading()}
                </>
            )
        } else if (Object.keys(course).length === 0) {
            return (
                <>
                    <h3
                        style={{
                            fontFamily: "Inter var, sans-serif"
                        }}
                    >Course not found</h3>
                    {breaksIfLoading()}
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )
        } else {
            return (
                <>
                    <h1>{name}</h1>
                    <hr />
                    <b>Description</b>
                    <DescriptionReadMore description={description} />
                    <b>Institution</b>
                    <p>{institution}</p>
                    <b>Price</b>
                    <ul>
                        <li>In District: ${inDistrictPrice}</li>
                        <li>In State: ${inStatePrice}</li>
                        <li>Out of State: ${outOfStatePrice}</li>
                    </ul>
                    <b>Transfer Institutions</b>
                    <ul>{transferArr()}</ul>
                    <ProfessorReadMore professorData={professorData} />
                    <br />
                    <Button
                        variant="primary"
                        onClick={onClick}
                        style={{
                            fontFamily: "Inter, sans-serif",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px",
                            marginTop: "15px",
                        }}
                    >
                        Apply now
                    </Button>
                    {breaksIfLoading()}
                </>
            );
            // if (!sessionStorage.getItem("token")) {
            //     return (
            //         <React.Fragment>
            //             <h5
            //                 style={{
            //                     marginLeft: "20px",
            //                 }}
            //             >
            //                 Login or Register to see this course
            //             </h5>
            //             <div
            //                 style={{
            //                     marginLeft: "15px",
            //                     marginBottom: "30px",
            //                 }}
            //             >
            //                 <LoginButton />
            //                 <RegisterButton />
            //             </div>
            //             <div
            //                 style={{
            //                     marginLeft: "20px",
            //                     marginRight: "20px",
            //                     marginBottom: "30px",
            //                     fontFamily: "Inter var, sans-serif",
            //                     filter: "blur(5px)",
            //                 }}
            //             >
            //                 <h1>********</h1>
            //                 <hr />
            //                 <b>Description</b>
            //                 <p>********</p>
            //                 <b>Institution</b>
            //                 <p>********</p>
            //                 <b>Price</b>
            //                 <ul>
            //                     <li>In District: $********</li>
            //                     <li>In State: $********</li>
            //                     <li>Out of State: $********</li>
            //                 </ul>
            //                 <b>Transfer Institutions</b>
            //                 <ul>{transferArr()}</ul>
            //                 <ProfessorReadMore professorData={professorData} hide={true} />
            //                 <br />
            //                 <Button
            //                     variant="primary"
            //                     // onClick={onClick}
            //                     style={{
            //                         fontFamily: "Inter, sans-serif",
            //                         borderTopLeftRadius: "25px",
            //                         borderBottomLeftRadius: "25px",
            //                         borderBottomRightRadius: "25px",
            //                         borderTopRightRadius: "25px",
            //                         marginTop: "15px",
            //                     }}
            //                     disabled
            //                 >
            //                     Apply now
            //                 </Button>
            //                 {breaksIfLoading()}
            //             </div>
            //         </React.Fragment>
            //     );

            // } else {
            //     return (
            //         <>
            //             <h1>{name}</h1>
            //             <hr />
            //             <b>Description</b>
            //             <DescriptionReadMore description={description} />
            //             <b>Institution</b>
            //             <p>{institution}</p>
            //             <b>Price</b>
            //             <ul>
            //                 <li>In District: ${inDistrictPrice}</li>
            //                 <li>In State: ${inStatePrice}</li>
            //                 <li>Out of State: ${outOfStatePrice}</li>
            //             </ul>
            //             <b>Transfer Institutions</b>
            //             <ul>{transferArr()}</ul>
            //             <ProfessorReadMore professorData={professorData} />
            //             <br />
            //             <Button
            //                 variant="primary"
            //                 onClick={onClick}
            //                 style={{
            //                     fontFamily: "Inter, sans-serif",
            //                     borderTopLeftRadius: "25px",
            //                     borderBottomLeftRadius: "25px",
            //                     borderBottomRightRadius: "25px",
            //                     borderTopRightRadius: "25px",
            //                     marginTop: "15px",
            //                 }}
            //             >
            //                 Apply now
            //             </Button>
            //             {breaksIfLoading()}
            //         </>
            //     );
            // }
        }
    }

    const breaksIfLoading = () => {
        if (loading) {
            return (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )
        } else if (course.length === 0) {
            return (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )

        } else {
            return (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )
        }
    }

    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>
                    {sessionStorage.getItem("token") ? `${name}, ${institution} | CourseFind` : "Login or Register to view this course | CourseFind"}
                </title>
                <meta name="description" content={sessionStorage.getItem("token") ? description : "Login or Register to view this course | CourseFind"} />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                {checkIfLoading()}
            </Container>
            <SearchFooter />
        </div>
    );
}

export default withRouter(CoursePage);