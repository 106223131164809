import React, { useState, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import {
    Menu,
    MenuHeader,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { Autocomplete } from "@mui/material";
let transferSchools = require('./transfer_schools.json');

export const Transferability = () => {
    const [transferUniversities, setTransferUniversities] = useState("");
    const [selectedSchool, setSelectedSchool] = useState("");
    const history = useHistory();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (params.get("transferability")) {
            setTransferUniversities(params.get("transferability"));
        }
    }, [])

    const manageTransferSchoolChange = (e) => {
        let tempTransferUniversities = transferUniversities;
        if (e.target.checked) {
            if (tempTransferUniversities.length > 0) {
                tempTransferUniversities += `,${e.target.value}`;
            } else {
                tempTransferUniversities += `${e.target.value}`;
            }
        } else {
            tempTransferUniversities = tempTransferUniversities.replace(`${e.target.value},`, "");
            tempTransferUniversities = tempTransferUniversities.replace(`,${e.target.value}`, "");
            tempTransferUniversities = tempTransferUniversities.replace(`${e.target.value}`, "");
        }
        setTransferUniversities(tempTransferUniversities);
    }


    const addSchoolToTransferUniversities = (school) => {
        let tempTransferUniversities = transferUniversities;
        if (!tempTransferUniversities.includes(school)) {
            if (tempTransferUniversities.length > 0) {
                tempTransferUniversities += `,${school}`;
            } else {
                tempTransferUniversities += `${school}`;
            }
        }
        setTransferUniversities(tempTransferUniversities);
        setSelectedSchool(""); 
    }

    const applyChanges = () => {
        if (transferUniversities.length > 0) {
            params.set("transferability", transferUniversities);
            
            params.delete("page");
        } else {
            params.delete("transferability");
        }
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }

    const clearChanges = () => {
        params.delete("transferability");
        setTransferUniversities("");
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    }
    
    const populateMenuItems = () => {
        const selectedSchools = transferUniversities.split(',').filter(school => school !== '');
        return selectedSchools.map((school, key) => {
            return (
                <div
                    style={{
                        marginLeft: "12.5px",
                        marginBottom: "10px",
                    }}
                    key={key}
                >
                    <input
                        value={school}
                        type="checkbox"
                        onChange={manageTransferSchoolChange}
                        checked={transferUniversities.includes(school)}
                    />
                    <label
                        style={{
                            paddingLeft: "12.5px",
                            paddingRight: "12.5px",
                        }}
                    >
                        {school}
                    </label>
                </div>
            )
        })
    }

    const enterTransferability = () => {
        return (
            <Autocomplete
                sx={{
                    width: 200
                }}
                id="custom-input-demo"
                options={transferSchools}
                onInputChange={(event, value) => {
                    setSelectedSchool(value);
                }}
                onChange={(event, value) => {
                    if (value) {
                        addSchoolToTransferUniversities(value);
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && selectedSchool) {
                        addSchoolToTransferUniversities(selectedSchool);
                    }
                }}
                value={selectedSchool} 
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <Form.Control
                            placeholder='Search for a university'
                            {...params.inputProps}
                            style={{
                                borderRadius: "20px",
                                marginLeft: "10px",
                                marginBottom: "15px",
                            }}
                        />
                    </div>
                )}
            />
        )
    }


    const buttons = () => {
        return (
            <React.Fragment>
                <Button
                    variant="primary"
                    style={{
                        marginLeft: "10px",
                        marginRight: "5px",
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    onClick={applyChanges}
                    id="apply-filter"
                >
                    Apply
                </Button>
                <Button
                    variant="outline-primary"
                    style={{
                        fontFamily: "Inter vars, sans-serif",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                        borderTopRightRadius: "25px"
                    }}
                    onClick={clearChanges}
                >
                    Clear
                </Button>
            </React.Fragment>
        )
    }

    if (window.innerWidth <= 414) {
        return (
            <>
                <Menu
                    menuButton={<Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Transfer</Button>}
                    transition
                >
                    <MenuHeader>Filter by Public University</MenuHeader>
                    {enterTransferability()}
                    {populateMenuItems()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <>
                <Menu
                    menuButton={<Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Transferability</Button>}
                    transition
                >
                    <MenuHeader>Filter by Public University</MenuHeader>
                    {enterTransferability()}
                    {populateMenuItems()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <>
                <Menu
                    menuButton={<Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Transferability</Button>}
                    transition
                >
                    <MenuHeader>Filter by Public University</MenuHeader>
                    {enterTransferability()}
                    {populateMenuItems()}
                    {buttons()}
                </Menu>
            </>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <>
                <Menu
                    menuButton={<Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Transferability</Button>}
                    transition
                >
                    <MenuHeader>Filter by Public University</MenuHeader>
                    {enterTransferability()}
                    {populateMenuItems()}
                    {buttons()}
                </Menu>
            </>
        );
    } else {
        return (
            <>
                <Menu
                    menuButton={<Button
                        variant="outline-primary"
                        style={{
                            marginLeft: "10px",
                            borderTopLeftRadius: "25px",
                            borderBottomLeftRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderTopRightRadius: "25px"
                        }}
                    >Transferability</Button>}
                    transition
                >
                    <MenuHeader>Filter by Public University</MenuHeader>
                    {enterTransferability()}
                    {populateMenuItems()}
                    {buttons()}
                </Menu>
            </>
        );
    }
}