import { Container, Row } from 'react-bootstrap';
import { menuOne } from '../searchFooter/menuOne';
import { menuTwo } from '../searchFooter/menuTwo';
import { socials } from '../searchFooter/socials';

export default function SearchFooter() {
    return (
        <div
            style={{
                backgroundColor: "#0080ff",
                color: "white",
                marginBottom: "-100px",
                paddingTop: "2.75rem",
                paddingBottom: ".25rem",
                clipPath: "polygon(0 0, 100% 2.5vw, 100% 100%, 0 calc(100%))",
                fontFamily: "Avenir Next"
            }}        
        >
            <Container
                style={{
                    fontFamily: "Inter var, sans-serif",
                    backgroundColor: "#0080ff",
                }}
            >
                <Row>
                    {menuOne()}
                    {menuTwo()}
                </Row>
                <hr />
                <Row>
                    {socials()}
                </Row>
            </Container>
        </div>
    )
}