import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import { Helmet } from 'react-helmet';
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";
import { useEffect } from "react";

function AboutUs() {
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);

    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>About Us | CourseFind</title>
                <meta name="description" content="Learn about CourseFind's mission to help students find the perfect community college courses and apply with ease." />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                <h1>About Us</h1>
                <br />
                <p>Welcome to CourseFind, an online service dedicated to helping public university students from a diverse range of institutions find and enroll in community college courses that:</p>
                <ul>
                    <li>Transfer seamlessly to their university</li>
                    <li>Offer the most affordable options based on their location</li>
                    <li>Meet their desired course quality criteria</li>
                </ul>
                <p>In addition to simplifying the search process, CourseFind also automates the application process for select community colleges, making it easier than ever for students to access quality, affordable education.</p>
                <br />
                <h2>Our Story</h2>
                <p>CourseFind was founded by Logan Ankenbrandt, a former community college student in the Lone Star College System in Houston, Texas. Logan's personal experience at community college allowed him to receive a quality education, build lasting relationships, and advance his professional and academic endeavors, all at a fraction of the cost of attending a public university. Inspired by his journey, Logan set out to create CourseFind to remove the friction of finding and accessing quality education at an affordable price for public university students.</p>
                <br />
                <h2>Our Mission</h2>
                <p>At CourseFind, our mission is to empower students to make informed decisions about their education by providing them with the tools and resources necessary to discover community college courses that align with their academic goals and financial needs. We believe that everyone should have access to affordable, quality education and are committed to breaking down barriers that stand in the way of achieving this vision.</p>
                <br />
                <h2>Our Values</h2>
                <ul>
                    <li><strong>Student-Centric:</strong> We put the needs of our users at the forefront of everything we do, ensuring that our platform is designed to meet their unique requirements and deliver an exceptional user experience.</li>
                    <li><strong>Accessibility:</strong> We strive to make higher education more accessible and affordable for all students, regardless of their background or financial situation.</li>
                    <li><strong>Innovation:</strong> We continuously work to improve and expand our platform, leveraging cutting-edge technology and feedback from our users to create a seamless and efficient experience.</li>
                    <li><strong>Integrity:</strong> We hold ourselves to the highest ethical standards, maintaining transparency and honesty in all aspects of our operations and interactions with our users.</li>
                </ul>
                <p>We are excited to have you join us on our journey to reshape the higher education landscape and create new opportunities for students everywhere. If you have any questions or would like to learn more about CourseFind, please feel free to <a href="mailto:support@course-find.com">contact us</a>.</p>
                <br />
                <br />
                <br />
            </Container>
            <SearchFooter />
        </div>
    );
}

export default withRouter(AboutUs);
