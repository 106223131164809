import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import { Helmet } from 'react-helmet';
import { useEffect } from "react";
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";

function PrivacyPolicy() {
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);
    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>CourseFind | Privacy Policy</title>
                <meta name="description" content="Read CourseFind's Privacy Policy to understand how we collect, use, and protect your personal information while using our platform." />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                <h1>Privacy Policy</h1>
                <p>At CourseFind, we are committed to protecting the privacy of our users. This Privacy Policy outlines the types of information we collect, how we use and protect it, and your rights concerning your personal data. By using our website and services, you agree to the collection and use of your information in accordance with this policy.</p>

                <br />
                <h2>Information We Collect</h2>
                <p>We collect various types of information to provide and improve our services. The information we collect includes:</p>
                <br />
                <h5
                    style={{
                        fontStyle: "italic"
                    }}
                >Personal Information</h5>
                <p>When you use our services, we may ask you to provide personal information, such as your name, email address, and other contact details. This information is used to create your account, provide customer support, and communicate with you about our services.</p>
                <br />
                <h5
                    style={{
                        fontStyle: "italic"
                    }}
                >Usage Information</h5>
                <p>We collect information about your interactions with our website, including the pages you visit, search queries, and the duration of your visits. This information helps us improve the user experience and tailor our services to better meet your needs.</p>
                <br />
                <h5
                    style={{
                        fontStyle: "italic"
                    }}
                >Cookies and Tracking Technologies</h5>
                <p>We use cookies and similar tracking technologies to collect information about your browsing activities, preferences, and device information, such as your IP address, browser type, and operating system. This data helps us analyze usage patterns and improve our services. You can manage your cookie preferences through your browser settings.</p>
                <br />
                <h2>How We Use Your Information</h2>
                <p>CourseFind uses the collected information for various purposes, including:</p>
                <ul>
                    <li>To provide and maintain our services</li>
                    <li>To improve and personalize your user experience</li>
                    <li>To communicate with you about our services, promotions, and updates</li>
                    <li>To provide customer support and respond to your inquiries</li>
                    <li>To monitor and analyze the usage of our services</li>
                    <li>To detect, prevent, and address technical issues and potential security threats</li>
                </ul>
                <br />
                <h2>Information Sharing and Disclosure</h2>
                <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third parties under the following circumstances:</p>
                <ul>
                    <li>To comply with legal obligations or respond to lawful requests from government authorities</li>
                    <li>To enforce our Terms of Service and protect the rights, property, and safety of CourseFind and its users</li>
                    <li>To facilitate a merger, acquisition, or sale of all or a portion of our assets</li>
                    <li>With your consent, to provide specific services or information you have requested</li>
                </ul>
                <br />
                <h2>Security of Your Information</h2>
                <p>We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of electronic transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
                <br />
                <h2>Your Rights</h2>
                <p>You have certain rights concerning your personal information, including:</p>
                <ul>
                    <li>The right to access, update, or delete your personal information stored on our platform</li>
                    <li>The right to restrict or object to the processing of your personal information</li>
                    <li>The right to withdraw your consent to our use of your personal information at any time</li>
                </ul>
                <p>To exercise any of these rights, please contact us at <a href="mailto:support@course-find.com">support@course-find.com</a>.</p>
                <br />
                <h2>Third-Party Links</h2>
                <p>Our website may contain links to third-party websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <br />
                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>
                <p>Email: <a href="mailto:support@course-find.com">support@course-find.com</a></p>
                <br />
                <br />
                <br />
            </Container>
            <SearchFooter />
        </div>
    );
}


export default withRouter(PrivacyPolicy);