import React from 'react';
import Course from './course';
import { LoginButton, RegisterButton } from '../nav/buttons';

export default function SearchResult({ courses }) {
    if (courses.length > 0) {
        return courses.map((course, key) => {
            // check if it's the last element
            if (sessionStorage.getItem("token")) {
                if (key === courses.length - 1) {
                    return <Course key={key} course={course} />;
                } else {
                    return (
                        <React.Fragment key={key}>
                            <Course course={course} />
                            <hr
                                style={{
                                    width: "90%",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginBottom: "20px",
                                    marginTop: "20px",
                                    border: "1px solid #e0e0e0"
                                }}
                            />
                        </React.Fragment>
                    );
                }
            } else {
                // hide all the results after the third one and show the following:
                // 1. Login or Register to see the rest of the results
                // 2. <LoginButton /> and <RegisterButton />
                if (key === 2) {
                    return (
                        <React.Fragment key={key} >
                            <Course course={course} />
                            <br />
                            <div
                                style={{
                                    marginLeft: "20px",
                                    marginBottom: "50px",
                                }}
                            >
                                {/* <h5>
                                    Login or Register to see the rest of the results
                                </h5> */}
                                <div
                                    style={{
                                        marginLeft: "-5px"
                                    }}
                                >
                                    <LoginButton />
                                    <RegisterButton />
                                </div>

                            </div>
                        </React.Fragment>
                    );
                } // else if the key is 0 or 1 then show the results
                else if (key === 0 || key === 1) {
                    return (
                        <React.Fragment key={key}>
                            <Course course={course} />
                            <hr
                                style={{
                                    width: "90%",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginBottom: "20px",
                                    marginTop: "20px",
                                    border: "1px solid #e0e0e0"
                                }}
                            />
                        </React.Fragment>
                    );
                } // else hide the results
                else {
                    return (
                        <React.Fragment key={key}>
                            <Course course={course} hide={true} />
                            {/* <hr
                                style={{
                                    width: "90%",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    marginBottom: "20px",
                                    marginTop: "20px",
                                    border: "1px solid #e0e0e0"
                                }}
                            /> */}
                        </React.Fragment>
                    );
                }
            }
        });
    } else {
        return (
            <div>
                <h5
                    style={{
                        marginLeft: "20px"
                    }}
                >
                    No results found
                </h5>
            </div>
        );
    }
}