import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import { Container } from "react-bootstrap";
import Loading from "../components/Loading";
import { useLocation } from "react-router-dom";
import CommentsReadMore from "../components/search/commentsReadMore";
import RateMyProfessorPopUp from "../components/search/rateMyProfessorPopUp";
import { AiOutlineLeft, AiOutlineDown } from "react-icons/ai";
import axios from "axios";
import { Helmet } from "react-helmet";
import { LoginButton, RegisterButton } from "../components/nav/buttons";
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";

function ProfessorPage() {
    const [loading, setLoading] = useState(true);
    const [professor, setProfessor] = useState(null);
    const [professorName, setProfessorName] = useState("");
    const [professorDescription, setProfessorDescription] = useState("");
    const [professorCollapsed, setProfessorCollapsed] = useState(false);
    const params = new URLSearchParams(useLocation().search);
    const id = params.get("id");
    
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);

    useEffect(() => {
        const fetchProfessorData = async () => {
            try {
                const res = await axios.get(`https://sba3mbqeen6dae6iselkesn7cy0eauhh.lambda-url.us-east-2.on.aws/?rate_my_average_id=${id}`);
                setProfessor(res.data);
                setProfessorName(res.data.name);
                setProfessorDescription(`Discover Community College Courses taught by ${res.data.name}`);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };

        fetchProfessorData();
    }, [id]);

    const returnProfessorInformationButton = () => {
        if (sessionStorage.getItem("token")) {
            if (professorCollapsed) {
                return (
                    <span
                        onClick={() => setProfessorCollapsed(!professorCollapsed)}
                        style={{
                            marginLeft: "10px",
                            color: "black",
                            textDecoration: "none",
                            outline: "none",
                            // change the mouse cursor to a pointer
                            cursor: "pointer",
                        }}
                    >
                        <AiOutlineDown
                            style={{
                                marginBottom: "3.25px"
                            }}
                        />
                    </span>
                )
            } else {
                return (
                    <span
                        onClick={() => setProfessorCollapsed(!professorCollapsed)}
                        style={{
                            marginLeft: "10px",
                            color: "black",
                            textDecoration: "none",
                            outline: "none",
                            // change the mouse cursor to a pointer
                            cursor: "pointer",
                        }}
                    >
                        <AiOutlineLeft
                            style={{
                                marginBottom: "3.25px"
                            }}
                        />
                    </span>
                )
            }

        } else {
            return (
                <span
                    style={{
                        marginLeft: "10px",
                        color: "black",
                        textDecoration: "none",
                        outline: "none",
                        // change the mouse cursor to a pointer
                        cursor: "pointer",
                    }}
                >
                    <AiOutlineLeft
                        style={{
                            marginBottom: "3.25px"
                        }}
                    />
                </span>
            )
        }
    }
    
    const calculateAverageRatings = () => {
        let totalRatings = 0;
        let totalDifficulty = 0;
        let totalClarity = 0;
        let totalHelpfulness = 0;

        professor.courses.forEach((course) => {
            course.ratings.forEach((rating) => {
                totalRatings += 1;
                totalDifficulty += rating.difficulty_rating;
                totalClarity += rating.clarity_rating;
                totalHelpfulness += rating.helpfulness_rating;
            });
        });

        const averageDifficulty = Math.round((totalDifficulty / totalRatings) * 100) / 100;
        const averageClarity = Math.round((totalClarity / totalRatings) * 100) / 100;
        const averageHelpfulness = Math.round((totalHelpfulness / totalRatings) * 100) / 100;
        const averageRating = Math.round(((averageDifficulty + averageClarity + averageHelpfulness) / 3) * 100) / 100;

        return {
            averageDifficulty,
            averageClarity,
            averageHelpfulness,
            averageRating,
        };
    };

    const averageRatings = professor && calculateAverageRatings();

    const renderRatings = () => {
        if (!professor || !professor.courses) {
            return null;
        }

        return professor.courses.map((course, index) => (
            <div key={index}>
                {course.ratings.map((rating, ratingIndex) => {
                    const date = new Date(rating.date);
                    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                    return (
                        <div key={ratingIndex}>
                            <>
                                <li>Date: {formattedDate}</li>
                                <ul>
                                    <li><CommentsReadMore comments={rating.comment} /></li>
                                    <li>Format: {rating.online ? "Online" : "In Person"}</li>
                                    <li>Attendance: {rating.attendance === "" || rating.attendance === null ? "N/A" : rating.attendance}</li>
                                    <li>User Grade: {rating.user_grade === "" || rating.user_grade === null ? "N/A" : rating.user_grade}</li>
                                </ul>
                            </>
                        </div>
                    );
                })}
            </div>
        ));
    };

    const renderProfessorInformation = () => {
        if (sessionStorage.getItem("token")) {
            if (professorCollapsed) {
                return (
                    <>
                        <b>Ratings
                            <span
                                style={{
                                    color: "#0080ff",
                                    marginLeft: "5px",
                                }}
                            >
                                <RateMyProfessorPopUp professor={professor} />
                            </span>
                            {returnProfessorInformationButton()}
                        </b>
                        <ul>
                            {renderRatings()}
                        </ul>
                    </>
                )
            } else {
                return (
                    <>
                        <b>Ratings
                            <span
                                style={{
                                    color: "#0080ff",
                                    marginLeft: "5px",
                                }}
                            >
                                <RateMyProfessorPopUp professor={professor} />
                            </span>
                            {returnProfessorInformationButton()}
                        </b>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </>
                )
            }
        } else {
            return (
                <>
                    <b>Ratings
                        <span
                            style={{
                                color: "#0080ff",
                                marginLeft: "5px",
                            }}
                        >
                            {/* <RateMyProfessorPopUp professor={professor} disable={true} /> */}
                        </span>
                        {returnProfessorInformationButton()}
                    </b>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )

        }
    }

    const content = () => {
        if (loading) {
            return (
                <>
                    <Loading />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            );
        }

        if (!professor) {
            return (
                <>
                    <h3>Professor not found</h3>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            );
        }

        return (
            <>
                <h1>{professor.name}</h1>
                <hr />
                <b>Average Ratings</b>
                <ul>
                    <li>Overall: {averageRatings.averageRating}</li>
                    <li>Difficulty: {averageRatings.averageDifficulty}</li>
                    <li>Clarity: {averageRatings.averageClarity}</li>
                    <li>Helpfulness: {averageRatings.averageHelpfulness}</li>
                </ul>
                {renderProfessorInformation()}
            </>
        );
        // if (sessionStorage.getItem("token")) {

        //     if (loading) {
        //         return (
        //             <>
        //                 <Loading />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //             </>
        //         );
        //     }

        //     if (!professor) {
        //         return (
        //             <>
        //                 <h3>Professor not found</h3>
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //                 <br />
        //             </>
        //         );
        //     }

        //     return (
        //         <>
        //             <h1>{professor.name}</h1>
        //             <hr />
        //             <b>Average Ratings</b>
        //             <ul>
        //                 <li>Overall: {averageRatings.averageRating}</li>
        //                 <li>Difficulty: {averageRatings.averageDifficulty}</li>
        //                 <li>Clarity: {averageRatings.averageClarity}</li>
        //                 <li>Helpfulness: {averageRatings.averageHelpfulness}</li>
        //             </ul>
        //             {renderProfessorInformation()}
        //         </>
        //     );

        // } else {
        //     return (
        //         <>
        //             <h5
        //                 style={{
        //                     marginLeft: "15px", 
        //                 }}
        //                 >
        //                 Login or Register to see the professor's information
        //             </h5>
        //             <div
        //                 style={{
        //                     marginLeft: "15px",
        //                     marginBottom: "50px",
        //                 }}
        //             >
        //                 <LoginButton />
        //                 <RegisterButton />
        //             </div>
        //             <div
        //                 style={{
        //                     marginLeft: "20px",
        //                     marginBottom: "50px",
        //                     filter: "blur(5px)",
        //                 }}
        //             >
        //                 <h1>*******</h1>
        //                 <hr />
        //                 <b>Average Ratings</b>
        //                 <ul>
        //                     <li>Overall: *******</li>
        //                     <li>Difficulty: *******</li>
        //                     <li>Clarity: *******</li>
        //                     <li>Helpfulness: *******</li>
        //                 </ul>
        //                 {renderProfessorInformation()}
        //             </div>
        //         </>
        //     );   
        // }
    };


    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>
                    {sessionStorage.getItem("token") ? `${professorName} | CourseFind` : "Login or Register to view this professor | CourseFind"}
                </title>
                <meta name="description" content={sessionStorage.getItem("token") ? professorDescription : "Login or Register to view this professor"} />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                {content()}
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />    
            <SearchFooter />
        </div>
    );
}

export default withRouter(ProfessorPage);