// src/services/AnalyticsService.js
import axios from 'axios';

const API_BASE = 'https://xiq7acd8ce.execute-api.us-east-2.amazonaws.com/api';

export const recordView = async (data) => {
    return axios.post(`${API_BASE}/record`, data);
};

export const getActiveUsers = async () => {
    return axios.get(`${API_BASE}/active_users`);
};

export const getUsersPerRange = async (start, end) => {
    return axios.post(`${API_BASE}/users_per_range`, { start, end });
};

export const getPagesVisited = async () => {
    return axios.get(`${API_BASE}/pages_visited`);
};
