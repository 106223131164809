import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const menuOne = () => {
    if (window.innerWidth <= 414) {
        return (            
            <Col>
                <h2
                    style={{
                        fontWeight: "bold"
                    }}
                >Menu</h2>
                <Link 
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Home</Link>
                <br />
                <Link 
                    to="/register"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Register</Link>
                <br />
                <Link 
                    to="/login"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Login</Link>
            </Col>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (            
            <Col>
                <h2
                    style={{
                        fontWeight: "bold"
                    }}
                >Menu</h2>
                <Link 
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Home</Link>
                <br />
                <Link 
                    to="/register"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Register</Link>
                <br />
                <Link 
                    to="/login"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Login</Link>
            </Col>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (            
            <Col>
                <h2
                    style={{
                        fontWeight: "bold"
                    }}
                >Menu</h2>
                <Link 
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Home</Link>
                <br />
                <Link 
                    to="/register"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Register</Link>
                <br />
                <Link 
                    to="/login"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Login</Link>
            </Col>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (            
            <Col>
                <h2
                    style={{
                        fontWeight: "bold"
                    }}
                >Menu</h2>
                <Link 
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Home</Link>
                <br />
                <Link 
                    to="/register"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Register</Link>
                <br />
                <Link 
                    to="/login"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}    
                >Login</Link>
            </Col>
        );
    } else {
        return (
            <Col>
                <h2
                    style={{
                        fontWeight: "bold"
                    }}
                >Menu</h2>
                <Link
                    to="/"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Home</Link>
                <br />
                <Link
                    to="/register"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Register</Link>
                <br />
                <Link
                    to="/login"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Login</Link>
            </Col>
        );
    }
}