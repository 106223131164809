import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const menuTwo = () => {
    if (window.innerWidth <= 414) {
        return (
            <Col>
                <br />
                <Link 
                    to="/terms-and-conditions"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Terms and Conditions</Link>
                <br />
                <Link 
                    to="/privacy-policy"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Privacy Policy</Link>
                <br />
                <Link 
                    to="/contact-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Contact Us</Link>
                <br />
                <Link 
                    to="/about-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >About Us</Link>
            </Col>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <Col>
                <br />
                <Link 
                    to="/terms-and-conditions"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Terms and Conditions</Link>
                <br />
                <Link 
                    to="/privacy-policy"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Privacy Policy</Link>
                <br />
                <Link 
                    to="/contact-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Contact Us</Link>
                <br />
                <Link 
                    to="/about-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >About Us</Link>
            </Col>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 769) {
        return (
            <Col>
                <br />
                <Link 
                    to="/terms-and-conditions"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Terms and Conditions</Link>
                <br />
                <Link 
                    to="/privacy-policy"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Privacy Policy</Link>
                <br />
                <Link 
                    to="/contact-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Contact Us</Link>
                <br />
                <Link 
                    to="/about-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >About Us</Link>
            </Col>
        );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <Col>
                <br />
                <Link 
                    to="/terms-and-conditions"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Terms and Conditions</Link>
                <br />
                <Link 
                    to="/privacy-policy"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Privacy Policy</Link>
                <br />
                <Link 
                    to="/contact-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Contact Us</Link>
                <br />
                <Link 
                    to="/about-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >About Us</Link>
            </Col>
        );
    } else {
        return (
            <Col>
                <br />
                <Link
                    to="/terms-and-conditions"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Terms and Conditions</Link>
                <br />
                <Link
                    to="/privacy-policy"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Privacy Policy</Link>
                <br />
                <Link
                    to="/contact-us"
                    style={{
                        textDecoration: "none",
                        color: "#0080ff",
                    }}
                >Contact Us</Link>
            </Col>
        );
    }
}