import Loading from "../Loading";
import { useLocation } from "react-router-dom";
import { SearchPagination } from "../searchFooter/pagination";
import SearchResult from "./SearchResult";
import { Breaks } from "../searchFooter/breaks";

export default function SearchList({responseData, searchResults, count, loading, error }) {
    const params = new URLSearchParams(useLocation().search);
    const query = params.get("q");

    
    const pagination = () => {
        // num of pages is the count / 10 rounded up
        let numOfPages = Math.ceil(count / 10);
        if (!params.get("page")) {
            const page = 1;
            return <SearchPagination numOfPages={numOfPages} activePage={page} />;
        } else {
            const page = parseInt(params.get("page"));
            return <SearchPagination numOfPages={numOfPages} activePage={page} />;
        }
    }

    const wrongPage = () => {
        if ((parseInt(params.get("page")) > Math.ceil(count / 10) || parseInt(params.get("page")) < 1) && loading === false && error === false) {
            return (
                <>
                    <h4 style={{ marginLeft: "20px" }}>
                        Page {params.get("page")} does not exist</h4>
                    <Breaks searchResultLength={count} />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {pagination()}
                </>
            );
        } else {
            return (
                <>
                    {loading ? (
                        <>
                            <Loading />
                            <Breaks searchResultLength={null} />
                        </>
                    ) : (
                        <>
                            <SearchResult courses={searchResults} />
                            <Breaks searchResultLength={count} />
                            {pagination()}
                        </>
                    )}
                </>
            )
        }
    }

    return (
        <>
            <h4 style={{ marginLeft: "20px" }}>
                {count} courses matching "{query}"</h4>
            <hr style={{
                border: "1px solid #d3d3d3",
            }} />
            {wrongPage()}
        </>
    );
}