import Root from "./Root";
import { Route, Switch, useHistory } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import CoursePage from "./pages/CoursePage";
import ProfessorPage from "./pages/ProfessorPage";
import SearchPage from "./pages/SearchPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsService from "./pages/TermsService";
import Landing from "./pages/Landing";
import axios from "axios";

axios.defaults.baseURL = "http://localhost:8000/"

function App() {
  return (
    <Root>
      <Switch>
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/course" component={CoursePage} />
        <Route path="/professor" component={ProfessorPage} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/search" component={SearchPage} />
        <Route path="/terms-and-conditions" component={TermsService} />
        <Route exact path="/" component={Landing} />
      </Switch>
    </Root>
  )
}

export default App;
