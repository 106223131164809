import { howToIcons } from '../howto/icons';

export default function HowTo() {

    return (
        <div
            style={{
                fontFamily: "Avenir Next",
                paddingTop: "200px",
                paddingBottom: "175px",
                marginBottom: "75px",
                backgroundColor: "#0080ff",
                clipPath: "polygon(0 0, 100% 2.5vw, 100% 100%, 0 calc(100% - 2.5vw))",
                color: "white",
            }}
        >
            {howToIcons()}
        </div>
    );
}