import { Container } from "react-bootstrap";
import { withRouter } from "react-router";
import SearchFooter from "../components/search/SearchFooter";
import SearchNavBar from "../components/search/SearchNavBar";
import { Helmet } from "react-helmet";
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";
import { useEffect } from "react";

function ContactUs() {
    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);
    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
            }}
        >
            <Helmet>
                <title>Contact Us | CourseFind</title>
                <meta name="description" content="Get in touch with the CourseFind team for any inquiries, feedback, or support. We're here to help you navigate community college courses!" />
            </Helmet>
            <SearchNavBar />
            <br />
            <Container>
                <h1>Contact Us</h1>
                <br />
                <p>At CourseFind, we're committed to helping public university students discover and enroll in community college courses that suit their academic needs and goals. We understand that the process can be overwhelming, and we're here to help you every step of the way. If you have any questions, concerns, or suggestions, feel free to reach out to our dedicated support team!</p>

                <br />
                <h3>Get in Touch</h3>
                <p>For any inquiries or assistance, don't hesitate to email our support team at <a href="mailto:support@course-find.com">support@course-find.com</a>. Our team is available Monday through Friday, from 9 am to 5 pm PST. We'll make sure to get back to you within 24 hours during our business days.</p>

                <br />
                <h3>Feedback</h3>
                <p>We're continuously working to improve CourseFind and would love to hear your thoughts! If you have any suggestions or feedback, please email us at <a href="mailto:feedback@course-find.com">feedback@course-find.com</a>. Your input helps us better serve our users and create a more seamless experience for students like you.</p>

                <br />
                <p>Thank you for choosing CourseFind as your partner in the pursuit of higher education. We look forward to helping you find the right community college courses and ensuring a successful application process!</p>

                <br />
                <br />
                <br />
            </Container>
            <SearchFooter />
        </div>

    )
}

export default withRouter(ContactUs);
