import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';

export default function SearchInput() {
    const params = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const [query, setQuery] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        params.set("q", query);
        history.push({
            pathname: "/search",
            search: params.toString(),
        });
        window.location.reload();
    };
    
    if (window.innerWidth <= 414) {
        return (
            <InputGroup>
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    style={{
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                        marginRight: "20px",
                        marginLeft: "10px",
                    }}
                />
            </InputGroup>
        );
    } else if (window.innerWidth <= 769 && window.innerWidth > 414) {
        return (
            <InputGroup
                style={{
                    marginRight: "15px",
                    marginLeft: "10px",
                }}
            >
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    style={{
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                    }}
                />
                <Button
                    variant="primary"
                    id="button-addon2"
                    onClick={handleSubmit}
                    style={{
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                    }}>
                    <SearchIcon />
                </Button>
            </InputGroup>
        );
    } else if (window.innerWidth <= 1150 && window.innerWidth > 769) {
        return (
            <InputGroup
                style={{
                    width: "90%",
                    marginLeft: "100px"
                }}
            >
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    style={{
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                    }}
                />
                <Button
                    variant="primary"
                    id="button-addon2"
                    onClick={handleSubmit}
                    style={{
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                    }}>
                    <SearchIcon />
                </Button>
            </InputGroup>
        );
    } else if (window.innerWidth <= 1367 && window.innerWidth > 1150) {
        return (
            <InputGroup
                style={{
                    width: "90%",
                    marginLeft: "200px"
                }}
            >
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    style={{
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                    }}
                />
                <Button
                    variant="primary"
                    id="button-addon2"
                    onClick={handleSubmit}
                    style={{
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                    }}>
                    <SearchIcon />
                </Button>
            </InputGroup>
    );
    } else if (window.innerWidth <= 1921 && window.innerWidth > 1367) {
        return (
            <InputGroup
                style={{
                    width: "65%",
                    marginLeft: "250px"
                }}
            >
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                    style={{
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                    }}
                />
                <Button
                    variant="primary"
                    id="button-addon2"
                    onClick={handleSubmit}
                    style={{
                        borderTopRightRadius: "25px",
                        borderBottomRightRadius: "25px",
                    }}
                >
                    <SearchIcon />
                </Button>
            </InputGroup>
        );
    } else {
        return (
            <InputGroup
                style={{
                    width: "65%",
                    marginLeft: "35%"
                }}
            >
                <Form.Control
                    placeholder="Search by course, subject, or keyword..."
                    aria-label="Search by course, subject, or keyword..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                />
                <Button 
                    variant="primary" 
                    id="button-addon2"
                    onClick={handleSubmit}
                >
                    <SearchIcon />
                </Button>
            </InputGroup>
        );
    }
}