import { useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { FilterNav } from "../components/search/FilterNav";
import SearchFooter from "../components/search/SearchFooter";
import SearchList from "../components/search/SearchList";
import SearchNavBar from "../components/search/SearchNavBar";
import { useLocation } from "react-router-dom";
import { CurrentFilterNav } from "../components/search/CurrentFilterNav";
import { Helmet } from "react-helmet";
import { getSessionId } from "../utils/session";
import { recordView } from "../services/AnalyticsService";
const abbrevToState = require("./abbrev_to_state.json");


function SearchPage() {
    const [searchResults, setSearchResults] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    const params = new URLSearchParams(useLocation().search);
    const query = params.get("q");

    useEffect(() => {
        const sessionId = getSessionId();
        const page = window.location.pathname; // current page

        recordView({ sessionId, page, referrer: document.referrer || 'direct' });
    }, []);

    useEffect(() => {
        retrieveSearchResults();
    }, []);
    
    const retrieveSearchResults = async () => {
        try {
            const transfer = params.get("transferability");
            const minAverageRatings = params.get("minAverageRatings");
            const maxAverageRatings = params.get("maxAverageRatings");
            const minClarityRatings = params.get("minClarityRatings");
            const maxClarityRatings = params.get("maxClarityRatings");
            const minDifficultyRatings = params.get("minDifficultyRatings");
            const maxDifficultyRatings = params.get("maxDifficultyRatings");
            const minHelpfulnessRatings = params.get("minHelpfulnessRatings");
            const maxHelpfulnessRatings = params.get("maxHelpfulnessRatings");
            const minPrice = params.get("minPrice");
            const maxPrice = params.get("maxPrice");
            const residency = params.get("residency");
            const sort = params.get("sort");
            const page = params.get("page") || 1;

            const queryParams = new URLSearchParams({
                query: query,
                page_no: page,
            });

            if (transfer) {
                queryParams.set("transfer", transfer);
            }

            if (minAverageRatings) {
                queryParams.set("min_average_ratings", parseInt(minAverageRatings));
            }

            if (maxAverageRatings) {
                queryParams.set("max_average_ratings", parseInt(maxAverageRatings));
            }

            if (minClarityRatings) {
                queryParams.set("min_clarity_ratings", parseInt(minClarityRatings));
            }

            if (maxClarityRatings) {
                queryParams.set("max_clarity_ratings", parseInt(maxClarityRatings));
            }

            if (minDifficultyRatings) {
                queryParams.set("min_difficulty_ratings", parseInt(minDifficultyRatings));
            }

            if (maxDifficultyRatings) {
                queryParams.set("max_difficulty_ratings", parseInt(maxDifficultyRatings));
            }

            if (minHelpfulnessRatings) {
                queryParams.set("min_helpfulness_ratings", parseInt(minHelpfulnessRatings));
            }

            if (maxHelpfulnessRatings) {
                queryParams.set("max_helpfulness_ratings", parseInt(maxHelpfulnessRatings));
            }

            if (sort) {
                if (sort === "asc") {
                    queryParams.set("sort_by_selected_price", true);
                }
            }
            
            if (residency) {
                let residencyArray = residency.split(" ");
                let state = residencyArray[residencyArray.length - 2]
                state = abbrevToState[state];
                const zipCode = residencyArray[residencyArray.length - 1];

                if (state) {
                    queryParams.set("user_state", state);
                }

                if (zipCode) {
                    queryParams.set("user_zip", zipCode.replace('"', ''));
                }
            }

            if (minPrice) {
                queryParams.set("min_price", parseInt(minPrice));
            }

            if (maxPrice) {
                queryParams.set("max_price", parseInt(maxPrice));
            }

            const response = await axios.get(`https://y75d3xcn2aupq6kabuuji2here0cuoaa.lambda-url.us-east-2.on.aws/?${queryParams.toString()}`);
            const res = response.data;

            setResponseData(res['results']);
            setCount(res['count']);
            setSearchResults(res['results']);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };


    const checkParams = () => {
        let paramsArray = [];
        for (let [key, value] of params) {
            if (key !== "q" && key !== "page") {
                paramsArray.push([key, value]);
            }
        }

        if (paramsArray.length > 0) {
            return <CurrentFilterNav />;
        } else {
            return;
        }
    }

    return (
        <>
            <Helmet>
                <title>{query} | CourseFind</title>
                <meta name="description" content="Search Results" />
            </Helmet>
            <SearchNavBar />
            <FilterNav 
                responseData={responseData}
            />
            {checkParams()}
            <hr
                style={{
                    border: "1px solid #d3d3d3",
                }}
            />
            <SearchList 
                responseData={responseData} 
                searchResults={searchResults} 
                count={count} 
                loading={loading} 
                error={error} 
            />
            <SearchFooter />
        </>
    );
}

export default withRouter(SearchPage);