import { Navbar } from "react-bootstrap";
import Logo from '../../assets/img/logo.png'

export const AuthNavBar = () => {
    return (
        <Navbar bg="light" variant="light">
            <Navbar.Brand
                href="/"
                style={{
                    marginLeft: "10px",
                    fontFamily: "Inter var, sans-serif",
                    color: "#0080ff",
                }}
            >
                <h1
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <img
                        src={Logo}
                        style={{
                            width: "200px",
                            height: "auto",
                        }}
                        alt="CourseFind logo"
                    />
                </h1>
            </Navbar.Brand>
        </Navbar>
    )
}