import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DescriptionReadMore from './descriptionReadMore';
import ProfessorReadMore from './professorReadMore';
import { professorDataAcquisitionCourse } from './professorDataAcquisitionCourse';
import { LoginButton, RegisterButton } from '../nav/buttons';

export default function Course({ course, hide }) {
    const [professorData, setProfessorData] = useState([]);

    useEffect(() => {
        const fetchProfessorData = async () => {
            const data = await professorDataAcquisitionCourse(course.professor_ids);
            setProfessorData(data);
        };

        if (course && course.professor_ids && course.professor_ids.length > 0) {    
            fetchProfessorData();
        }
    }, [course]);

    const onClick = () => {
        if (hide) {
            return;
        } else {
            let link = `${course.application}?utm_source=course-find.com`
            window.open(link, "_blank");
        }
    }

    const transferArr = () => {
        if (course.transfer.length > 0) {
            return course.transfer.map((transfer, key) => {
                return <li key={key}>{transfer}</li>;
                // if (hide) return <li key={key}>********</li>;
                // else return <li key={key}>{transfer}</li>;
            })
        } else {
            return <li>No transfer institutions found</li>;
        }
    }

    return (
        <div
            style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "30px",
                fontFamily: "Inter var, sans-serif",
            }}
        >
            <Link
                to={`/course?id=${course._id}`}
                style={{
                    textDecoration: "none",
                    color: "black",
                    fontWeight: "bold"
                }}
            >
                <h5 id={course._id}>
                    {course.name}
                </h5>
            </Link>
            <b>
                Description
            </b>
            <DescriptionReadMore description={course.description} />
            <b>
                Institution
            </b>
            <p>{course.institution}</p>
            <b>
                Price
            </b>
            <ul>
                <li
                    style={{
                        fontWeight: course.selected === "inDistrict" ? "bold" : "normal",
                    }}
                >In District: ${parseFloat(course.price.inDistrict).toFixed(2)}</li>
                <li
                    style={{
                        fontWeight: course.selected === "inState" ? "bold" : "normal",
                    }}
                >In State: ${parseFloat(course.price.inState).toFixed(2)}</li>
                <li
                    style={{
                        fontWeight: course.selected === "outOfState" ? "bold" : "normal",
                    }}
                >Out of State: ${parseFloat(course.price.outOfState).toFixed(2)}</li>
            </ul>
            <b>
                Transfer Institutions
            </b>
            <ul>{transferArr()}</ul>
            <ProfessorReadMore professorData={professorData} />
            <br />
            <Button
                variant="primary"
                onClick={onClick}
                style={{
                    fontFamily: "Inter, sans-serif",
                    borderTopLeftRadius: "25px",
                    borderBottomLeftRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderTopRightRadius: "25px",
                    marginTop: "15px",
                }}
            >
                Apply now
            </Button>
        </div>
    )

    // if (hide) {
    //     // blur the course
    //     return (
    //         <div
    //             style={{
    //                 marginLeft: "20px",
    //                 marginRight: "20px",
    //                 marginBottom: "30px",
    //                 fontFamily: "Inter var, sans-serif",
    //                 filter: "blur(5px)",
    //             }}
    //         >
    //             <Link
    //                 to={`/course?id=${course._id}`}
    //                 style={{
    //                     textDecoration: "none",
    //                     color: "black",
    //                     fontWeight: "bold"
    //                 }}
    //             >
    //                 <h5 id={course._id}>
    //                     ********
    //                 </h5>
    //             </Link>
    //             <b>
    //                 Description
    //             </b>
    //             <p>********</p>
    //             <b>
    //                 Institution
    //             </b>
    //             <p>********</p>
    //             <b>
    //                 Price
    //             </b>
    //             <ul>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "inDistrict" ? "bold" : "normal",
    //                     }}
    //                 >In District: $********</li>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "inState" ? "bold" : "normal",
    //                     }}
    //                 >In State: $********</li>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "outOfState" ? "bold" : "normal",
    //                     }}
    //                 >Out of State: $********</li>
    //             </ul>
    //             <b>
    //                 Transfer Institutions
    //             </b>
    //             <ul>{transferArr()}</ul>
    //             <ProfessorReadMore professorData={professorData} hide={true} />
    //             <br />
    //             <Button
    //                 variant="primary"
    //                 onClick={onClick}
    //                 disabled={true}
    //                 style={{
    //                     fontFamily: "Inter, sans-serif",
    //                     borderTopLeftRadius: "25px",
    //                     borderBottomLeftRadius: "25px",
    //                     borderBottomRightRadius: "25px",
    //                     borderTopRightRadius: "25px",
    //                     marginTop: "15px",
    //                 }}
    //             >
    //                 Apply now
    //             </Button>
    //         </div>

    //     ) 
    // } else {
    //     return (
    //         <div
    //             style={{
    //                 marginLeft: "20px",
    //                 marginRight: "20px",
    //                 marginBottom: "30px",
    //                 fontFamily: "Inter var, sans-serif",
    //             }}
    //         >
    //             <Link
    //                 to={`/course?id=${course._id}`}
    //                 style={{
    //                     textDecoration: "none",
    //                     color: "black",
    //                     fontWeight: "bold"
    //                 }}
    //             >
    //                 <h5 id={course._id}>
    //                     {course.name}
    //                 </h5>
    //             </Link>
    //             <b>
    //                 Description
    //             </b>
    //             <DescriptionReadMore description={course.description} />
    //             <b>
    //                 Institution
    //             </b>
    //             <p>{course.institution}</p>
    //             <b>
    //                 Price
    //             </b>
    //             <ul>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "inDistrict" ? "bold" : "normal",
    //                     }}
    //                 >In District: ${parseFloat(course.price.inDistrict).toFixed(2)}</li>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "inState" ? "bold" : "normal",
    //                     }}
    //                 >In State: ${parseFloat(course.price.inState).toFixed(2)}</li>
    //                 <li
    //                     style={{
    //                         fontWeight: course.selected === "outOfState" ? "bold" : "normal",
    //                     }}
    //                 >Out of State: ${parseFloat(course.price.outOfState).toFixed(2)}</li>
    //             </ul>
    //             <b>
    //                 Transfer Institutions
    //             </b>
    //             <ul>{transferArr()}</ul>
    //             <ProfessorReadMore professorData={professorData} />
    //             <br />
    //             <Button
    //                 variant="primary"
    //                 onClick={onClick}
    //                 style={{
    //                     fontFamily: "Inter, sans-serif",
    //                     borderTopLeftRadius: "25px",
    //                     borderBottomLeftRadius: "25px",
    //                     borderBottomRightRadius: "25px",
    //                     borderTopRightRadius: "25px",
    //                     marginTop: "15px",
    //                 }}
    //             >
    //                 Apply now
    //             </Button>
    //         </div>
    //     )
    // }
}
